import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import QuestionEditorRowsTable from './QuestionEditorRowsTable/QuestionEditorRowsTable';
import { QuestionEditorColumnsTable } from './QuestionEditorColumnsTable/QuestionEditorColumnsTable';
import { QuestionEditorPasteEditsModal } from './QuestionEditorPasteEditsModal/QuestionEditorPasteEditsModal';
import { QuestionEditorAddCustomRowModal } from './QuestionEditorAddCustomRowModal/QuestionEditorAddCustomRowModal';
import { returnMultiSelectionData } from '../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import returnQuestionEditorActionsData from '../../../shared/helpers/returnQuestionEditorActionsData/returnQuestionEditorActionsData';
import { returnUpdatedHierarchyQuestionsData } from '../../../shared/helpers/returnUpdatedHierarchyQuestionsData/returnUpdatedHierarchyQuestionsData';
import { returnQuestionEditorActionButtonsData } from '../../../shared/helpers/returnQuestionEditorActionButtonsData/returnQuestionEditorActionButtonsData';
import { QuestionEditorDeleteCustomRowModal } from './QuestionEditorDeleteCustomRowModal/QuestionEditorDeleteCustomRowModal';

const body = ''

export const QuestionEditor = ({ questions, allQuestions, handleClose, handleEditQuestion, source, editHierarchy, analysisType }) => {
  let sourceOfLastSelected = 'firstColumn'
  if (questions.checkedNum.length > 0) {
    sourceOfLastSelected = questions.checkedNum[questions.checkedNum.length - 1].source
  }

  const returnAllQuestionsHelper = (theQuestions) => {
    // Merge row and column questions, and add firstOrSecond key/value pair to each question,
    // which is needed to properly update them
    const updatedQuestions = [];
    if (theQuestions.scndColumn) {
      theQuestions.firstColumn.forEach(el => el.firstOrSecond = "firstColumn")
      updatedQuestions.push(...theQuestions.firstColumn.filter(el => el.selected))

      theQuestions.scndColumn.forEach(el => el.firstOrSecond = "scndColumn")
      updatedQuestions.push(...theQuestions.scndColumn.filter(el => el.selected))
    } else {
      updatedQuestions.push(...theQuestions.firstColumn.filter(el => el.selected))
    }
    return updatedQuestions
  }

  const dispatch = useDispatch();
  const { crossTabQuestionEditorCopyData, defaultLanguage } = useSelector((theState) => (theState.setInitialDataReducer));
  const [questionsState, setQuestionsState] = useState(editHierarchy ? returnUpdatedHierarchyQuestionsData(JSON.parse(JSON.stringify(allQuestions)), questions[sourceOfLastSelected].find(el => el.selected).hierarchy) : returnAllQuestionsHelper(JSON.parse(JSON.stringify(questions))));
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [mergeRowsBtnActive, setMergeRowsBtnActive] = useState(false);
  const [rowsSelectedState, setRowsSelectedState] = useState([])
  const [showCategorizeDialog, setShowCategorizeDialog] = useState(false)
  const [selectedGroupTab, setSelectedGroupTab] = useState(0)
  const [checkedGroupItems, setCheckedGroupItems] = useState([])
  const [checkedSubgroupItems, setCheckedSubgroupItems] = useState([])
  const [checkedSummary, setCheckedSummary] = useState(false)
  const [checkedSwap, setCheckedSwap] = useState(false)
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [showPasteEditsModal, setShowPasteEditsModal] = useState({ show: false, compatibility: '', compatibleQuestions: [] });
  const [showAddCustomRowModal, setShowAddCustomRowModal] = useState({ show: false, selectedQuestion: null, selectedCustomRow: null });
  const [showDeleteCustomRowModal, setShowDeleteCustomRowModal] = useState({ show: false, selectedQuestion: null, selectedCustomRows: null });

  const [didMount, setDidMount] = useState(true)

  useEffect(() => {
    if (didMount) {
      setCheckedSwap(questionsState[selectedQuestion].swapRowsCols ? true : false)
      setCheckedSummary(questionsState[selectedQuestion].summaryValuesOnly ? true : false)
      setDidMount(false)
    }
  }, [didMount, questionsState, selectedQuestion])

  useEffect(() => {
    if (questionsState && questionsState.length > 0) {
      const selectedGroups = questionsState[selectedQuestion].groups.filter(group => group.selected)
      const selectedColumns = questionsState[selectedQuestion].subGroups ? questionsState[selectedQuestion].subGroups.filter(group => group.selected) : []
      const selectedRows = [...selectedGroups, ...selectedColumns]
      setMergeRowsBtnActive(selectedRows.length > 1)
      if (questionsState[selectedQuestion].groups.every(el => el.active === false)) {
        setUpdateButtonDisabled(true);
      } else if (questionsState[selectedQuestion].subGroups.length > 0 && questionsState[selectedQuestion].subGroups.every(el => el.active === false)) {
        setUpdateButtonDisabled(true);
      } else {
        setUpdateButtonDisabled(false);
      }
    }
  }, [questionsState, selectedQuestion])

  const onActiveChange = (id, groupType) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questionsState));
    const activeQuestion = updatedQuestions.find(question => {
      return question.id === questionsState[selectedQuestion].id;
    });

    activeQuestion[groupType].forEach(row => {
      if (row.id === id) {
        row.active = !row.active;
      }
      if (row.active) {
        row.suppress = false;
      }
    });
    setQuestionsState(updatedQuestions);
  };

  const onSuppressChange = (id, groupType) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questionsState));
    const activeQuestion = updatedQuestions.find(question => {
      return question.id === questionsState[selectedQuestion].id;
    });

    activeQuestion[groupType].forEach(row => {
      if (row.id === id) {
        if (row.active) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Suppress is not avaliable on active column' } })
          row.suppress = false;
          return;
        }
        row.suppress = !row.suppress;
      }
    });
    setQuestionsState(updatedQuestions);
  }

  const onSelectedChange = (e, itemId, groupType) => {
    const shouldDefineRange = !!e.shiftKey
    const updatedQuestions = JSON.parse(JSON.stringify(questionsState));
    const activeQuestion = updatedQuestions.find(question => {
      return question.id === questionsState[selectedQuestion].id;
    });

    activeQuestion[groupType] = returnMultiSelectionData(
      activeQuestion[groupType],
      groupType === 'groups' ? checkedGroupItems : checkedSubgroupItems,
      shouldDefineRange,
      itemId,
      groupType === 'groups' ? (data) => setCheckedGroupItems(data) : (data) => setCheckedSubgroupItems(data),
      'selected'
    )
    setQuestionsState(updatedQuestions);

    const rowsSelected = activeQuestion.groups.filter(row => {
      return row.selected;
    });
    setRowsSelectedState(rowsSelected);
  };

  const onQuestionSelect = (e) => {
    setDidMount(true)
    setSelectedQuestion(e.selected);
    setCheckedSwap(questionsState[selectedQuestion].swapRowsCols ? true : false)

    const activeQuestion = questionsState.find(question => {
      return question.id === questionsState[selectedQuestion].id
    });
    const groupssSelected = activeQuestion.groups.filter(row => {
      return row.selected;
    });
    const subGroupsSelected = activeQuestion.subGroups ?
      activeQuestion.subGroups.filter(row => {
        return row.selected;
      }) : []

    setRowsSelectedState([...groupssSelected, ...subGroupsSelected]);
  };

  const handleUpdate = () => {
    questionsState.forEach(el => {
      questions.firstColumn.forEach(col => {
        if (el.id === col.id && col.selected && el.firstOrSecond === "firstColumn") {
          col.content = el.content
          col.groups = el.groups
          col.subGroups = el.subGroups
          col.swapRowsCols = checkedSwap
          col.summaryValuesOnly = checkedSummary
        } else if (el.id === col.id && col.selected) {
          col.content = el.content
          col.groups = el.groups
          col.subGroups = el.subGroups
          col.swapRowsCols = checkedSwap
          col.summaryValuesOnly = checkedSummary
        }
      })
      if (questions.scndColumn) {
        questions.scndColumn.forEach(col => {
          if (el.id === col.id && col.selected && el.firstOrSecond === "scndColumn") {
            col.content = el.content
            col.groups = el.groups
            col.subGroups = el.subGroups
            col.swapRowsCols = checkedSwap
            col.summaryValuesOnly = checkedSummary
          }
        })
      }
    })
    if (editHierarchy) {
      handleEditQuestion(questionsState, editHierarchy)
    } else {
      handleEditQuestion(questions)
    }
  }

  const onChangeQuestionContent = (e) => {
    const updatedQuestionsState = [...questionsState]
    updatedQuestionsState[selectedQuestion].content = e.target.value
    setQuestionsState(updatedQuestionsState)
  }

  const onChangeQuestionRowText = (e, id, groupType) => {
    const updatedQuestionsState = [...questionsState]
    updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id).text = e.target.value
    setQuestionsState(updatedQuestionsState)
  }

  const onChangeQuestionRowWeightValue = (e, id, groupType) => {
    const updatedQuestionsState = [...questionsState]
    updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id).weightValue = e.target.value
    setQuestionsState(updatedQuestionsState)
  }

  const onChangeQuestionCode = (e, id, groupType, codePart) => {
    const updatedQuestionsState = [...questionsState]
    if (codePart) {
      const code = updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id).code.split(':');
      const from = codePart === 'from' ? e.target.value : code[0];
      const to = codePart === 'to' ? e.target.value : code[1];
      updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id).code = `${from}:${to}`;
    } else {
      updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id).code = e.target.value;
    }
    setQuestionsState(updatedQuestionsState)
  }

  const deleteRow = (id, groupType) => {
    const updatedQuestionsState = [...questionsState]
    updatedQuestionsState[selectedQuestion][groupType] = updatedQuestionsState[selectedQuestion][groupType].filter(row => row.id !== id)
    setQuestionsState(updatedQuestionsState)
  }

  const duplicateRow = (id, groupType) => {
    const updatedQuestionsState = [...questionsState];
    const row = updatedQuestionsState[selectedQuestion][groupType].find(row => row.id === id);
    const newRow = { ...row, id: uuid() };
    updatedQuestionsState[selectedQuestion].subGroups.push(newRow);
    setQuestionsState(updatedQuestionsState)
  }

  const toggleCategorizeDialog = () => {
    setShowCategorizeDialog(!showCategorizeDialog)
  }

  const onSelectedGroupTab = (e) => {
    setSelectedGroupTab(e.selected)
  }
  const onAddCategoryItem = (formData) => {
    const updatedQuestionsState = [...questionsState]
    updatedQuestionsState[selectedQuestion].subGroups.push({
      code: "0:0",
      text: formData.title,
      editedText: formData.title,
      selected: false,
      active: true,
      id: uuid()
    })
    setQuestionsState(updatedQuestionsState)
  }

  const actionsHandler = (e) => {
    const updatedQuestionsState = returnQuestionEditorActionsData(e, questionsState, selectedQuestion, questions, rowsSelectedState, sourceOfLastSelected, body, toggleCategorizeDialog, selectedGroupTab, dispatch, crossTabQuestionEditorCopyData, setShowPasteEditsModal, setShowAddCustomRowModal, setShowDeleteCustomRowModal)
    if (selectedGroupTab === 0) {
      setCheckedGroupItems([])
    } else {
      setCheckedSubgroupItems([])
    }
    setQuestionsState(updatedQuestionsState)
  }

  const updateQuestionStateRowsColumns = (type, question, itemsState) => {
    const questionsStateCopy = [...questionsState]
    const activeQuestion = questionsStateCopy.find(el => el.id === question.id)
    activeQuestion[type] = itemsState
    setQuestionsState(questionsStateCopy)
  }

  const swapRowsAndColumns = () => {
    const swapRolsColsValue = questionsState[selectedQuestion].swapRowsCols
    if (swapRolsColsValue === undefined || swapRolsColsValue === false) {
      questionsState[selectedQuestion].swapRowsCols = true
    } else {
      questionsState[selectedQuestion].swapRowsCols = false

    }
    setDidMount(true)
  }
  const showSummaryValues = () => {
    const showSummaryValuesOnly = questionsState[selectedQuestion].summaryValuesOnly
    if (showSummaryValuesOnly === undefined || showSummaryValuesOnly === false) {
      questionsState[selectedQuestion].summaryValuesOnly = true
    } else {
      questionsState[selectedQuestion].summaryValuesOnly = false

    }
    setDidMount(true)
  }
  const items = returnQuestionEditorActionButtonsData(mergeRowsBtnActive, questionsState[selectedQuestion]?.id, crossTabQuestionEditorCopyData, questionsState[selectedQuestion]?.groups, selectedGroupTab)
  const questionStateSelectedQuestion = questionsState[selectedQuestion];
  const isCreateHeaderDisabled = !(questionStateSelectedQuestion.type === 'f' || questionStateSelectedQuestion.type === 'h' || questionStateSelectedQuestion.type === 'l' || questionStateSelectedQuestion.type === 's' || questionStateSelectedQuestion.type === 'd')

  const deleteMergedEntity = (question, rowColumn, groupType) => {
    const updatedQuestionsState = [...questionsState];
    const questionIndex = updatedQuestionsState.findIndex(el => el.id === question.id)
    const rowColumnIndex = updatedQuestionsState[questionIndex][groupType].findIndex(el => el.id === rowColumn.id)
    updatedQuestionsState[questionIndex][groupType].splice(rowColumnIndex, 1)
    setQuestionsState(updatedQuestionsState);
  }

  const tabTitleHandler = (question) => {
    switch (question.firstOrSecond) {
      case "firstColumn":
        return `${question.title} (r)`;
      case "scndColumn":
        return `${question.title} (c)`;
      default:
        return question.title
    }
  }

  return (
    <Dialog
      tabIndex={-1}
      width="75%"
      height="90%"
      className="question-editor"
      onClose={handleClose}
      title={`${editHierarchy ? 'Edit hierarchy' : 'Edit question'}`}>
      {
        showDeleteCustomRowModal.show &&
        <QuestionEditorDeleteCustomRowModal
          analysisType={analysisType}
          defaultLanguage={defaultLanguage}
          closeQuestionEditor={handleClose}
          checkedQuestions={questions.checkedNum}
          selectedQuestion={showDeleteCustomRowModal.selectedQuestion}
          selectedCustomRows={showDeleteCustomRowModal.selectedCustomRows}
          handleClose={() => setShowDeleteCustomRowModal({ show: false, selectedQuestion: null, selectedCustomRow: null })}
        />
      }
      {
        showAddCustomRowModal.show &&
        <QuestionEditorAddCustomRowModal
          analysisType={analysisType}
          defaultLanguage={defaultLanguage}
          checkedQuestions={questions.checkedNum}
          selectedQuestion={showAddCustomRowModal.selectedQuestion}
          selectedCustomRow={showAddCustomRowModal.selectedCustomRow}
          closeQuestionEditor={handleClose}
          handleClose={() => setShowAddCustomRowModal({ show: false, selectedQuestion: null, selectedCustomRow: null })}
        />
      }
      {
        showPasteEditsModal.show &&
        <QuestionEditorPasteEditsModal
          showPasteEditsModal={showPasteEditsModal}
          selectedQuestion={questionsState[selectedQuestion]}
          tabTitleHandler={tabTitleHandler}
          setQuestionsState={setQuestionsState}
          handleClose={() => setShowPasteEditsModal({ show: false, compatibility: '', compatibleQuestions: [] })}
        />
      }
      <TabStrip onSelect={onQuestionSelect} selected={selectedQuestion} className="analyze-tabs question-editor-tabs" tabIndex={-1} >
        {questionsState.map(question => {
          return (
            <TabStripTab key={question.id} title={tabTitleHandler(question)} tabIndex={-1}>
              <div className="d-flex align-items-center p-3">
                <div className="input-group">
                  <Input
                    autoFocus={true}
                    className="form-control"
                    onChange={onChangeQuestionContent}
                    value={questionsState[selectedQuestion].content}>
                  </Input>
                  <div className="input-group-append">
                    <DropDownButton
                      tabIndex={-1}
                      buttonClass="btn btn-outline-analyze rounded-left-0 mh-100 h-100"
                      text="Actions"
                      textField="actionName"
                      items={items}
                      onItemClick={actionsHandler} />
                  </div>
                </div>
              </div>
              <TabStrip className="response-tabs" selected={selectedGroupTab} onSelect={onSelectedGroupTab} tabIndex={-1} >
                <TabStripTab title="Response rows">
                  {
                    question.groups &&
                    <QuestionEditorRowsTable
                      source={sourceOfLastSelected}
                      question={question}
                      onActiveChange={onActiveChange}
                      onSelectedChange={onSelectedChange}
                      onChangeQuestionRowText={onChangeQuestionRowText}
                      updateQuestionState={updateQuestionStateRowsColumns}
                      setCheckedGroupItems={(data) => setCheckedGroupItems(data)}
                      onChangeQuestionRowWeightValue={onChangeQuestionRowWeightValue}
                      deleteMergedRow={deleteMergedEntity} />
                  }
                </TabStripTab>
                <TabStripTab title="Response columns">
                  {
                    (question.subGroups && question.subGroups.length > 0) || (question.type === 'f' || question.type === 'h' || question.type === 'l' || question.type === 's' || question.type === 'd') ?
                      <QuestionEditorColumnsTable
                        source={sourceOfLastSelected}
                        question={question}
                        onActiveChange={onActiveChange}
                        onSelectedChange={onSelectedChange}
                        onSuppressChange={onSuppressChange}
                        onChangeQuestionRowText={onChangeQuestionRowText}
                        updateQuestionState={updateQuestionStateRowsColumns}
                        setCheckedSubgroupItems={(data) => setCheckedSubgroupItems(data)}
                        onChangeQuestionRowWeightValue={onChangeQuestionRowWeightValue}
                        deleteMergedColumn={deleteMergedEntity}
                        onAddCategoryItem={onAddCategoryItem}
                        deleteRow={deleteRow}
                        onChangeQuestionCode={onChangeQuestionCode}
                        duplicateRow={duplicateRow}
                        isCreateHeaderDisabled={isCreateHeaderDisabled} />
                      :
                      <div className="alert alert-analyze text-center mt-4" role="alert">
                        The selected question doesn't have any response columns
                      </div>
                  }
                </TabStripTab>
              </TabStrip>
            </TabStripTab>
          )
        })}
      </TabStrip>
      <DialogActionsBar>
        <div className="d-flex flex-row justify-content-end align-items-center gap-lg">
          <div className="d-flex flex-row">
            <p className="align-self-center m-0 mr-2">Swap Rows/Columns</p>
            <Switch
              className="align-self-end"
              name="switch-checkbox"
              disabled={questionsState[selectedQuestion].subGroups.length === 0 ? true : false}
              checked={checkedSwap}
              onChange={() => swapRowsAndColumns()}
              size="small"
            />
          </div>
          <div className="d-flex flex-row">
            <p className="align-self-center m-0 mr-2">Show summary values only</p>
            <Switch
              className="align-self-end"
              name="switch-checkbox"
              checked={checkedSummary}
              onChange={() => showSummaryValues()}
              size="small"
            />
          </div>
        </div>
      </DialogActionsBar>
      <DialogActionsBar>
        <Button className="btn btn-secondary" id="close" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn btn-analyze" disabled={updateButtonDisabled} onClick={handleUpdate}>
          Update
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};