import type React from 'react';
import { useEffect } from 'react';

interface AlertProps {
  type: 'SUCCESS' | 'INFO' | 'ERROR';
  message: string | string[];
  autoDismiss?: number;
}

interface CustomAlertProps {
  alert: AlertProps;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ alert, onClose }) => {
  const { type, message, autoDismiss } = alert;

  useEffect(() => {
    if (autoDismiss && autoDismiss > 0) {
      const timer = setTimeout(() => {
        onClose();
      }, autoDismiss);
      return () => clearTimeout(timer);
    }
  }, [autoDismiss, onClose]);

  const getAlertClass = () => {
    switch (type) {
      case 'SUCCESS':
        return 'alert-success';
      case 'INFO':
        return 'alert-info';
      case 'ERROR':
        return 'alert-danger';
      default:
        return 'alert-primary';
    }
  };

  const alertClass = `alert ${getAlertClass()} alert-dismissible fade show mb-0 d-flex align-items-center z-index-9999`;

  const renderMessages = () => {
    if (Array.isArray(message)) {
      return (
        <div>
          {message.map((msg) => (
            <span className='d-block' key={msg}>
              {msg}
            </span>
          ))}
        </div>
      );
    }
    return <span>{message}</span>;
  };

  return (
    <div className={alertClass} role="alert">
      {renderMessages()}
      <button type="button" className="close" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default CustomAlert;
