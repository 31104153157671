import AdvancedWeightingTable from './AdvancedWeightingTable';
import { NumericTextBox } from '@progress/kendo-react-inputs';

interface StepThreeProps {
  templateData: TemplateData | null;
  capWeights: { [categoryText: string]: { minCap: number | null; maxCap: number | null } };
  setCapWeights: (value: { [categoryText: string]: { minCap: number | null; maxCap: number | null } } | ((prevCapWeights: { [categoryText: string]: { minCap: number | null; maxCap: number | null } }) => { [categoryText: string]: { minCap: number | null; maxCap: number | null } })) => void;
  maxIterations?: number | null;
  setMaxIterations: (value: number | null) => void;
}

const StepThree = ({ templateData, capWeights, setCapWeights, maxIterations, setMaxIterations }: StepThreeProps) => {
  if (!templateData || !templateData.subGroup || !templateData.subGroup.categories) {
    return <div>No data available.</div>;
  }

  const categories = templateData.subGroup.categories;

  const handleCapChange = (categoryText: string, capType: 'minCap' | 'maxCap', value: number | null) => {
    setCapWeights((prevCapWeights: { [categoryText: string]: { minCap: number | null; maxCap: number | null } }) => ({
      ...prevCapWeights,
      [categoryText]: {
        ...prevCapWeights[categoryText],
        [capType]: value,
      },
    }));
  };

  const preventKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <div>
        <AdvancedWeightingTable isEditable={false} data={templateData} />
      </div>
      <div className="pt-8">
        <div className="border p-4">
          <p className="font-weight-bold">Cap Weights</p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th />
                {categories.map(category => (
                  <th key={category.text}>{category.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Min Cap</td>
                {categories.map(category => (
                  <td key={category.text} className='min-w-100'>
                    <NumericTextBox
                      value={capWeights[category.text]?.minCap != null ? capWeights[category.text].minCap : null}
                      spinners={false}
                      min={0}
                      rounded={null}
                      onChange={(e) => handleCapChange(category.text, 'minCap', e.value)}
                      onKeyDown={preventKeyDown}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Max Cap</td>
                {categories.map(category => (
                  <td key={category.text} className='min-w-100'>
                    <NumericTextBox
                      value={capWeights[category.text]?.maxCap != null ? capWeights[category.text].maxCap : null}
                      spinners={false}
                      min={0}
                      rounded={null}
                      onChange={(e) => handleCapChange(category.text, 'maxCap', e.value)}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex mt-4'>
        <div className="font-weight-bold mr-4">Max Iterations</div>
        <NumericTextBox
          value={maxIterations}
          spinners={false}
          onChange={(e) => setMaxIterations(e.value)}
          rounded={null}
          className='w-25'
        />
      </div>
    </>
  );
};

export default StepThree;
