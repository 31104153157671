import type React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmActionModal from '../../../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import { fetchGetJson, fetchDelete } from '../../../../../../../../services/services';

import {
  DropDownButton,
  type DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column, type GridCellProps } from '@progress/kendo-react-grid';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';

interface AdvancedWeightItem {
  id: string;
  text: string;
  pop: number;
  definition: string;
  isAdvancedWeighting: boolean;
  advancedWeightId: string;
  selected?: boolean;
}

interface AlertProps {
  type: 'SUCCESS' | 'INFO' | 'ERROR';
  message: string | string[];
  autoDismiss?: number;
}

interface ExistingWeightsProps {
  token: string;
  datasetId: string;
  setCurrentStep: (step: number) => void;
  setMainTemplateData: (data: TemplateData | null) => void;
  setWeightName: (name: string) => void;
  setWeightDescription: (description: string) => void;
  setRoundingValues: (values: Rounding) => void;
  setCapWeights: (caps: { [key: string]: { minCap: number | null; maxCap: number | null } }) => void;
  setMaxIterations: (iterations: number | null) => void;
  setAlert: (alert: AlertProps | null) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

const ExistingAdvancedWeights: React.FC<ExistingWeightsProps> = ({
  token,
  datasetId,
  setCurrentStep,
  setMainTemplateData,
  setWeightName,
  setWeightDescription,
  setRoundingValues,
  setCapWeights,
  setMaxIterations,
  setAlert,
  isLoading,
  setIsLoading,
}) => {
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[2];

  const [advancedWeightsList, setAdvancedWeightsList] = useState<AdvancedWeightItem[] | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedWeightId, setSelectedWeightId] = useState<string | null>(null);

  const actionItems = [
    { text: 'Edit', icon: 'fa fas fa-edit', id: 'edit' },
    { text: 'Delete', icon: 'fa fas fa-trash-alt', id: 'delete' },
  ];


  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchWeights = async () => {
      setIsLoading(true);
      try {
        const response: AdvancedWeightItem[] = await fetchGetJson(
          `an/projects/${projectId}/analysis/${datasetId}/weights`,
          token
        );
        const advancedWeights = response.filter((weight) => weight.isAdvancedWeighting);
        setAdvancedWeightsList(advancedWeights);
      } catch (error) {
        console.error('Error fetching weights:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWeights();
  }, [datasetId, token]);

  const selectAction = (e: DropDownButtonItemClickEvent, dataItem: AdvancedWeightItem) => {
    if (e.item.id === 'delete') {
      setSelectedWeightId(dataItem.id);
      setShowDeleteModal(true);
    } else if (e.item.id === 'edit') {
      fetchWeightDefinition(dataItem.advancedWeightId);
    }
  };

  const fetchWeightDefinition = async (advancedWeightId: string) => {
    setIsLoading(true);
    try {
      const response: TemplateData = await fetchGetJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/definitions/${advancedWeightId}`,
        token
      );

      // Update the state in AdvancedWeightingWizard.tsx
      setMainTemplateData(response);
      setWeightName(response.id);
      setWeightDescription(response.description || '');
      setRoundingValues(
        response.settings?.rounding || {
          enabled: false,
          decimals: 0,
          roundingDirection: 0,
          roundingErrorDistribution: 0,
        }
      );
      setCapWeights(extractCapWeights(response));
      setMaxIterations(response.settings?.maxIterations || null);

      setCurrentStep(2);
    } catch (error) {
      console.error('Error fetching weight definition:', error);
      setAlert({
        type: 'ERROR',
        message: 'Error fetching weight definition',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const extractCapWeights = (templateData: TemplateData) => {
    const capWeights: { [key: string]: { minCap: number | null; maxCap: number | null } } = {};
    if (templateData.subGroup?.categories) {
      for (const category of templateData.subGroup.categories) {
        capWeights[category.text] = {
          minCap: category.minCap ?? null,
          maxCap: category.maxCap ?? null,
        };
      }
    }
    return capWeights;
  };

  const onDeleteAdvancedWeight = async () => {
    if (selectedWeightId) {
      setIsLoading(true);
      try {
        await fetchDelete(
          `an/projects/${projectId}/analysis/${datasetId}/weights/${selectedWeightId}`,
          token
        );
        setShowDeleteModal(false);

        setAdvancedWeightsList((prevWeights) =>
          prevWeights ? prevWeights.filter((weight) => weight.id !== selectedWeightId) : null
        );

        setSelectedWeightId(null);
      } catch (error) {
        console.error('Error deleting weight:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="d-flex flex-column h-100 p-2">
      {showDeleteModal && (
        <ConfirmActionModal
          projectName={'Advanced Weight'}
          handleClose={() => setShowDeleteModal(false)}
          deleteItem={onDeleteAdvancedWeight}
          type="element"
          errorMessage={null}
          setError={() => { }}
        />
      )}

      {isLoading ? (
        <InProgressOverlay type="fullscreen" theme="primary" />
      ) : advancedWeightsList && advancedWeightsList.length > 0 ? (
        <Grid data={advancedWeightsList} className="weight-grid p-3">
          <Column field="id" title="Advanced Weight Id" width={200} />
          <Column field="text" title="Text" />
          <Column field="pop" title="Population" width={100} />
          <Column
            width={100}
            cell={(props: GridCellProps) => (
              <td className="d-flex justify-content-end">
                <DropDownButton
                  iconClass="fa fas fa-ellipsis-h"
                  buttonClass="btn btn-secondary d-flex"
                  items={actionItems}
                  onItemClick={(e) => selectAction(e, props.dataItem as AdvancedWeightItem)}
                />
              </td>
            )}
          />
        </Grid>
      ) : (
        <div>No advanced weights found for this project.</div>
      )}
    </div>
  );
};

export default ExistingAdvancedWeights;
