import React, { useState, useEffect } from 'react';
import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { Dialog } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';

interface AdvancedWeightingTableProps {
  data: TemplateData;
  setMainTemplateData?: (value: TemplateData) => void;
  validateTemplateData?: (template: TemplateData) => Promise<{ validate: boolean }>;
  isEditable: boolean;
  isValidating?: boolean;
  onRemoveTarget?: (targetId: string) => void;
  selectedSubgroups?: { [key: string]: boolean };
  setSelectedSubgroups?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setShouldValidate?: (value: boolean) => void;
}

const AdvancedWeightingTable: React.FC<AdvancedWeightingTableProps> = ({
  data,
  isEditable,
  setMainTemplateData,
  validateTemplateData,
  isValidating,
  onRemoveTarget,
  selectedSubgroups,
  setSelectedSubgroups,
  setShouldValidate,
}) => {
  const [tableData, setTableData] = useState<TemplateData>(data);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pasteModalState, setPasteModalState] = useState<{
    show: boolean;
    targetId: string | null;
    pastedData: string;
  }>({ show: false, targetId: null, pastedData: '' });

  if (!tableData) {
    return null;
  }

  const onValidate = async () => {
    const response = await validateTemplateData?.(tableData);

    if (response?.validate) {
      setMainTemplateData?.(tableData);
    }
  };

  const preventKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      return;
    }
  };

  function mergeData(newData: TemplateData, existingData: TemplateData): TemplateData {
    const mergedData = JSON.parse(JSON.stringify(newData));

    if (!existingData || !existingData.subGroup) {
      return mergedData;
    }

    for (let catIndex = 0; catIndex < mergedData?.subGroup?.categories?.length; catIndex++) {
      const newCategory = mergedData?.subGroup?.categories[catIndex];
      const existingCategory = existingData?.subGroup?.categories.find(
        (cat) => cat.text === newCategory.text
      );

      if (existingCategory) {
        // Preserve targetBasePct if newCategory doesn't have it
        if (
          (newCategory.targetBasePct === undefined || newCategory.targetBasePct === '') &&
          existingCategory.targetBasePct !== undefined
        ) {
          newCategory.targetBasePct = existingCategory.targetBasePct;
        }

        newCategory.targetReferences = newCategory.targetReferences.map((newTargetRef: TargetReference) => {
          const existingTargetRef = existingCategory.targetReferences.find(
            (ref) => ref.id === newTargetRef.id
          );
          if (existingTargetRef) {

            for (
              let propIndex = 0;
              propIndex < newTargetRef.proportions.length;
              propIndex++
            ) {
              const newProportion = newTargetRef.proportions[propIndex];
              const existingProportion = existingTargetRef.proportions[propIndex];
              if (
                (newProportion.pct === undefined || newProportion.pct === '') &&
                existingProportion
              ) {
                newProportion.pct = existingProportion.pct;
              }
            }
          }
          return newTargetRef;
        });
      }
    }

    // Preserve targetPopulation if newData doesn't have it
    if (
      (newData.targetPopulation === undefined || newData.targetPopulation === null) &&
      existingData.targetPopulation !== undefined
    ) {
      mergedData.targetPopulation = existingData.targetPopulation;
    }

    return mergedData;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <not needed>
  useEffect(() => {
    const performMerge = () => {
      setIsLoading(true);
      setTableData((prevData) => {
        const merged = mergeData(data, prevData);
        return merged;
      });
      setIsLoading(false);
    };

    performMerge();
  }, [data]);

  const hasSubGroups = tableData.subGroup && tableData.subGroup.categories.length > 0;
  const categories = hasSubGroups ? tableData?.subGroup?.categories : [];
  const targets = tableData.targets;

  const openPasteModal = (targetId: string) => {
    setPasteModalState({
      show: true,
      targetId,
      pastedData: '',
    });
  };

  const closePasteModal = () => {
    setPasteModalState({
      show: false,
      targetId: null,
      pastedData: '',
    });
  };

  const applyPastedData = () => {
    const { pastedData, targetId } = pasteModalState;
    if (targetId && pastedData.trim()) {
      const updatedTableData = parsePastedData(pastedData, targetId, tableData);
      if (updatedTableData) {
        setTableData(updatedTableData);
        setShouldValidate?.(true);
      }
    }
    closePasteModal();
  };

  const parsePastedData = (
    pastedText: string,
    targetId: string,
    currentTableData: TemplateData
  ): TemplateData | null => {
    try {
      const rows = pastedText.trim().split('\n').map((row) => row.split('\t'));
      const updatedData = JSON.parse(JSON.stringify(currentTableData)); // Deep copy

      const categories = updatedData.subGroup?.categories || [];

      const target = updatedData.targets.find((t: { id: string; }) => t.id === targetId);
      if (!target) {
        throw new Error(`Target with id ${targetId} not found`);
      }

      let rowIndex = 0;

      for (let cellIdx = 0; cellIdx < target.targetCells.length; cellIdx++) {
        const cellRow = rows[rowIndex];
        if (!cellRow) {
          throw new Error(`Not enough rows provided for target ${targetId}`);
        }

        for (let i = 0; i < cellRow.length; i++) {
          const value = cellRow[i].trim();
          const numValue = value !== '' ? value.replace(/%/g, '') : '';

          const category = categories[i];
          if (category) {
            const targetReference = category.targetReferences.find((ref: { id: string; }) => ref.id === targetId);
            if (targetReference) {
              if (!targetReference.proportions[cellIdx]) {
                targetReference.proportions[cellIdx] = { pct: '' };
              }
              targetReference.proportions[cellIdx].pct = numValue;
            }
          }
        }
        rowIndex++;
      }

      return updatedData;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubgroupCheckboxChange = (categoryText: string, isChecked: boolean) => {
    setSelectedSubgroups?.(prevSelected => ({
      ...prevSelected,
      [categoryText]: isChecked,
    }));
  };

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <FullHeightSpinner />
        </div>
      ) : (
        <>
          <table className="table table-bordered">
            <thead>
              {hasSubGroups && (
                <tr>
                  <th>Target</th>
                  {categories?.map((category) => {
                    const isExcluded = !!category.targetReferences.length && category.targetReferences.every(ref => ref.excluded);
                    return (
                      <th key={category.text} style={{ backgroundColor: isExcluded ? '#ffcccc' : 'transparent' }}>
                        <div className="d-flex align-items-center">
                          {isEditable && <Checkbox checked={selectedSubgroups?.[category?.text] ?? false}
                            onChange={(e) => handleSubgroupCheckboxChange(category.text, e.value)}
                            size="small"
                            style={{ marginRight: '4px' }} />}
                          {category.text}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {hasSubGroups && (
                <>
                  <tr>
                    <td className="text-align-left">Total weight base</td>
                    <td
                      colSpan={categories?.length}
                      className="text-align-center font-weight-bold"
                    >
                      {isEditable ? (
                        <NumericTextBox
                          value={tableData.targetPopulation}
                          spinners={false}
                          min={0}
                          onChange={(e) => {
                            const newValue = e.value !== null ? e.value : null;
                            setTableData((prevData) => ({
                              ...prevData,
                              targetPopulation: newValue,
                            }));
                          }}
                          onKeyDown={preventKeyDown}
                          rounded={null}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <span>{tableData.targetPopulation ?? '-'}</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-align-left">Proportion of total</td>
                    {categories?.map((category, catIndex) => (
                      <td key={`proportion-${category.text}`}
                        style={{ backgroundColor: '#fff' }}
                      >
                        {isEditable ? (
                          <NumericTextBox
                            value={
                              category.targetBasePct !== undefined && category.targetBasePct !== ''
                                ? Number(category.targetBasePct)
                                : null
                            }
                            spinners={false}
                            min={0}
                            onChange={(e) => {
                              const newValue =
                                e.value != null ? e.value.toString() : '';
                              setTableData((prevData) => {
                                const updatedCategories = prevData.subGroup
                                  ? [...prevData.subGroup.categories]
                                  : [];
                                updatedCategories[catIndex] = {
                                  ...updatedCategories[catIndex],
                                  targetBasePct: newValue,
                                };
                                return {
                                  ...prevData,
                                  subGroup: {
                                    ...prevData.subGroup,
                                    categories: updatedCategories,
                                  },
                                };
                              });
                              setShouldValidate?.(true);
                            }}
                            onKeyDown={preventKeyDown}
                            rounded={null}
                            disabled={false}
                          />
                        ) : (
                          <span>
                            {category.targetBasePct ? `${category.targetBasePct}%` : '-'}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              )}
              {targets.map((target, targetIdx) => (
                <React.Fragment key={target.id}>
                  <tr>
                    <td
                      className="font-weight-bold text-align-left d-flex align-items-center"
                    >
                      {target.id}
                      <div>
                        {isEditable && onRemoveTarget && (
                          <Button
                            size="small"
                            className="text-danger ml-2"
                            type="button"
                            onClick={() => onRemoveTarget(target.id)}
                          >
                            - Remove Target
                          </Button>
                        )}
                      </div>
                    </td>
                    <td colSpan={hasSubGroups ? (categories?.length ?? 0) + 1 : 1} className='text-align-right'>{isEditable && (
                      <Button
                        size="small"
                        className="bg-transparent font-italic border-0"
                        type="button"
                        onClick={() => openPasteModal(target.id)}
                      >
                        <span>
                          <i className="fas fa-paste pr-2" />
                        </span>
                        Paste from spreadsheet
                      </Button>
                    )}</td>
                  </tr>
                  {target.targetCells.map((targetCell, targetCellIdx) => (
                    <tr key={`${targetIdx}-${targetCell.text}`}>
                      <td className="text-align-left">{targetCell.text}</td>
                      {hasSubGroups ? (
                        categories?.map((category, catIndex) => {
                          const targetReference = category.targetReferences.find(
                            (ref) => ref.id === target.id
                          );

                          if (
                            targetReference &&
                            targetReference.proportions.length > targetCellIdx
                          ) {
                            const proportion = targetReference.proportions[targetCellIdx];

                            return (
                              <td
                                key={`${catIndex}-${targetCell.text}`}
                                className='min-w-100'
                                style={{
                                  backgroundColor: targetReference.excluded ? '#ffcccc' : 'transparent',
                                }}
                              >
                                {targetReference.excluded ? (
                                  <span className="text-muted">{proportion.pct}</span>
                                ) : isEditable ? (
                                  <NumericTextBox
                                    rounded={null}
                                    value={
                                      proportion.pct !== undefined && proportion.pct !== ''
                                        ? Number(proportion.pct)
                                        : null
                                    }
                                    spinners={false}
                                    min={0}
                                    onKeyDown={preventKeyDown}
                                    onChange={(e) => {
                                      const newValue =
                                        e.value != null ? e.value.toString() : '';
                                      setTableData((prevData) => {
                                        const updatedData = { ...prevData };
                                        const updatedCategories = updatedData.subGroup
                                          ? [...updatedData.subGroup.categories]
                                          : [];
                                        const updatedCategory = {
                                          ...updatedCategories[catIndex],
                                        };
                                        const updatedTargetReferences = [
                                          ...updatedCategory.targetReferences,
                                        ];
                                        const targetRefIndex = updatedTargetReferences.findIndex(
                                          (ref) => ref.id === target.id
                                        );
                                        if (targetRefIndex !== -1) {
                                          const updatedTargetReference = {
                                            ...updatedTargetReferences[targetRefIndex],
                                          };
                                          const updatedProportions = [
                                            ...updatedTargetReference.proportions,
                                          ];
                                          updatedProportions[targetCellIdx] = {
                                            pct: newValue,
                                          };
                                          updatedTargetReference.proportions = updatedProportions;
                                          updatedTargetReferences[
                                            targetRefIndex
                                          ] = updatedTargetReference;
                                          updatedCategory.targetReferences = updatedTargetReferences;
                                          updatedCategories[catIndex] = updatedCategory;
                                          if (updatedData.subGroup) {
                                            updatedData.subGroup.categories = updatedCategories;
                                          }
                                        }
                                        return updatedData;
                                      });
                                      setShouldValidate?.(true);
                                    }}
                                    disabled={targetReference.excluded}
                                  />
                                ) : (
                                  <span>{proportion.pct ? `${proportion.pct}%` : '-'}</span>
                                )}
                              </td>
                            );
                          }
                          return <td key={`${catIndex}-${targetCell.text}`}>-</td>;
                        })
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {isEditable && (
            <Button
              type="button"
              onClick={onValidate}
              className="btn btn-outline-dark d-flex mb-4"
              disabled={isValidating}
            >
              {isValidating && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Validate
            </Button>
          )}
        </>
      )}
      {pasteModalState.show && (
        <Dialog
          title={`Paste from spreadsheet - Target: ${pasteModalState.targetId}`}
          onClose={closePasteModal}
          width={600}
        >

          <p className='pl-4 font-italic'>Paste your data from spreadsheet into the text area below:</p>
          <div className='p-4'>
            <TextArea
              value={pasteModalState.pastedData}
              onChange={(e) => setPasteModalState(prevState => ({ ...prevState, pastedData: e.target.value ?? '' }))}
              rows={10}
              style={{ width: '100%' }}
            />
          </div>
          <div className="k-dialog-buttongroup d-flex justify-content-end p-2">
            <Button className='btn btn-primary mr-2' onClick={applyPastedData} type='button'>
              Apply
            </Button>
            <Button className='btn btn-light' onClick={closePasteModal}>Cancel</Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default AdvancedWeightingTable;
