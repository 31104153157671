import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import clearQuestionItems from './chartClearItems.json'
import defaultOptions from '../../analysis/defaultOptionValues.json'
import { ExportModal } from '../../ExportModal/ExportModal';
import { OptionsDialog } from '../../OptionsDialog/OptionsDialog';
import ChartTypeContent from './ChartTypeContent/ChartTypeContent';
import DroppableColumn from '../../../DroppableColumn/DroppableColumn';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import UpgradePlanMessage from '../../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import returnInitialOptions from '../../../../shared/helpers/returnInitialOptions/returnInitialOptions';
import { returnUpdatedAnalysisBody } from '../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';
import returnChartOptionsInitialValues from '../../../../shared/helpers/returnChartOptionsInitialValues/returnChartOptionsInitialValues';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import WeightWizard from '../../WeightWizard/WeightWizard';
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';
import { QuestionEditor } from '../../QuestionEditor/QuestionEditor';
import { returnMultiSelectionData } from '../../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { returnAnalysisActionsItems } from '../../../../shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import { getUpdatedAnalysisOptions } from '../../../../shared/helpers/getUpdatedAnalysisOptions/getUpdatedAnalysisOptions';
import { LanguageSwitchButton } from '../../../../shared/helpers/languageSwitchButton/LanguageSwitchButton';
import { fetchPost as fetchCopyXML, fetchPostJson as updateCrosstabData, fetchPatchResOrJson } from '../../../../../../../../services/services';
import { returnBrowser } from '../../../../shared/helpers/returnBrowser/returnBrowser';
import { CopyXmlModal } from '../../CopyXmlModal/CopyXmlModal';
import { ExpandResultModal } from '../../ExpandResultModal/ExpandResultModal';
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import { AddReportsModal } from '../../Reports/Modals/AddReportsModal';
import { updateEditReport } from '../../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer';
import { copyAnalysisQuestions } from '../../../../shared/helpers/copyAnalysisQuestions/copyAnalysisQuestions';
import { pasteAnalysisQuestions } from '../../../../shared/helpers/pasteAnalysisQuestions/pasteAnalysisQuestions';

export const ChartTabContent = ({ onAutoSaveHandler, selectedTab, userData, datasetName, optionsData, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, rangeItems, analysisFunctionalities, onApplyWeightSet, setDidMountSession }) => {
  const { theData, defaultLanguage, languages, editingLanguage, showFullscreenResult, copyData } = useSelector((theState) => (theState.setInitialDataReducer));
  const { state, undoQuestionOptions, redoQuestionOptions, } = useSelector((theState) => (theState.chartStateReducer));
  const { token } = useSelector((state) => state.tokenStateReducer);
  const userSettings = useSelector(theState => theState.userSettingsReducer);
  const reportsData = useSelector((theState) => theState.analysisReportsReducer);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const projectId = params.name;
  const datasetId = params.dataset;
  const datasetType = params.analysisType;
  const zoomLevel = userSettings.zoomLevels.chartZoom;

  const [showQuestionEditorModal, setShowQuestionEditorModal] = useState(false);
  const [combineData, setCombineData] = useState([])
  const [showExportModal, setShowExportModal] = useState({ show: false, type: null, extension: null })
  const [errorMessage, setErrorMessage] = useState(null)
  const [showWeightWizard, setShowWeightWizard] = useState(false)
  const [showCopyXmlModal, setShowCopyXmlModal] = useState({ show: false, xmlCode: '' })
  const prevUpdateTable = state ? usePrevious(state.updateTable) : null
  const allColumnsDisabled = state.firstColumn.filter(e => !e.disabled).length === 0
  const [refreshTable, setRefreshTable] = useState(true)
  const [checkedColumns, setCheckedColumns] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [didMount, setDidMount] = useState(true)
  const [showAddReportsModal, setShowAddReportsModal] = useState(false)

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType) //export dropdown
  const actionItems = returnAnalysisActionsItems(undoQuestionOptions, redoQuestionOptions, false, datasetType, !state.firstColumn.length, state.checkedNum, copyData.elements) // actions dropdown

  useEffect(() => {
    const checkedRowsFiltered = state.firstColumn.filter((item) => item.selected).map(el => el.id)
    const checkedColumnsFiltered = state.scndColumn.filter((item) => item.selected).map(el => el.id)
    if (didMount) {
      const checkedRowsNums = checkedRowsFiltered.map(e => ({ source: 'firstColumn', id: e }))
      const checkedColumnsNums = checkedColumnsFiltered.map(e => ({ source: 'scndColumn', id: e }))
      const combinedNums = checkedRowsNums.concat(checkedColumnsNums)
      dispatch({ type: 'CHART_UPDATE_CHECKED_NUM', payload: combinedNums })
      setDidMount(false)
    }

    setCheckedColumns(checkedColumnsFiltered)
    setCheckedRows(checkedRowsFiltered)
  }, [state.firstColumn, state.scndColumn, didMount, dispatch])

  useEffect(() => {
    if ((state.updateTable && state.updateTable !== prevUpdateTable) || refreshTable) {
      let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
      }
      const body = returnUpdatedAnalysisBody(params.analysisType, 'g', state.newQuestionOptions, state.firstColumn, state.scndColumn.filter(item => !item.disabled), '', editingLanguage, defaultLanguage)
      updateCrosstabData(dataUrl, token, body)
        .then(res => {
          if (res && (res.message || res.error)) {
            if (state.firstColumn.length > 0) {
              setErrorMessage(res.error ? res.error : res.message)
            }
            dispatch({ type: 'CHART_SET_TABLE_STATE', payload: { value: null } })
          } else {
            dispatch({ type: 'CHART_SET_TABLE_STATE', payload: { value: res } })
            setErrorMessage(null)
          }
        })
      onAutoSaveHandler(state)
      setRefreshTable(false)
    }
  }, [state.newQuestionOptions, state.firstColumn, state.updateTable, dispatch, datasetId, token, refreshTable, onAutoSaveHandler, prevUpdateTable, datasetType, projectId, editingLanguage, params.analysisType, defaultLanguage])

  const clearQuestions = (props) => {
    if (props.item.action === "CHART_CLEAR_QUESTION_OPTIONS") {
      const chartValues = returnChartOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[5].chart, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: chartValues, options: initialOptions } })

    } else if (props.item.action === "CHART_CLEAR_QUESTIONS") {
      const chartValues = returnChartOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[5].chart, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: chartValues, options: initialOptions } })
    } else if (state.firstColumn.length > 0 || state.scndColumn.length > 0) {
      dispatch({ type: props.item.action })
      setErrorMessage(null)
    }
  }
  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'CHART_SELECT_QUESTION', payload: { id: id, theData: theData } })
  }

  const onCheckQuestion = (e, id, source, itemId, shouldDefineRange) => {
    if (source === "firstColumn") {
      const updatedRowsData = returnMultiSelectionData(state.firstColumn, checkedRows, shouldDefineRange, itemId, (data) => setCheckedColumns(data), 'selected')
      dispatch({ type: 'CHART_UPDATE_ROWS', payload: updatedRowsData })
    } else {
      const updatedColumnsData = returnMultiSelectionData(state.scndColumn, checkedColumns, shouldDefineRange, itemId, (data) => setCheckedRows(data), 'selected')
      dispatch({ type: 'CHART_UPDATE_COLUMNS', payload: updatedColumnsData })
    }
    setDidMount(true)

  }

  const handleOptionsUpdate = (newValues, values, data, option) => {
    if (state.firstColumn.some(ques => ques.selected)) {
      const updatedFirstColumn = [...state.firstColumn]
      const selectedQuestions = updatedFirstColumn.filter(el => el.selected)

      selectedQuestions.forEach(selectedQuestion => {
        const checkedQuestionIndex = updatedFirstColumn.findIndex((question) => question.id === selectedQuestion.id)
        const updatedValues = Object.assign({}, updatedFirstColumn[checkedQuestionIndex].options, newValues)

        updatedFirstColumn[checkedQuestionIndex].options = updatedValues;
      })

      dispatch({ type: 'CHART_SET_CHECKED_QUESTION_OPTIONS', payload: updatedFirstColumn })
    } else {
      const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, newValues)
      dispatch({ type: 'CHART_SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: values } })
    }

    const updatedOptionsWithInitialValue = getUpdatedAnalysisOptions(state, values)
    dispatch({ type: 'UPDATE_CHART_UNDO_QUESTION_OPTIONS', payload: [...undoQuestionOptions, updatedOptionsWithInitialValue] })

    handleOptionsDialogClose()
  }

  const setCombineFilterData = (id) => {
    const firstColumnCopy = [...state.firstColumn];
    const selectedQuestions = firstColumnCopy.filter(el => el.selected);

    if (selectedQuestions.length > 0) {
      const selectedFilterOrUniverseDataCopy = [...state.selectedFilterOrUniverseData];
      setCombineData(selectedFilterOrUniverseDataCopy);
    } else {
      if (id === "GFilterCode") {
        const filterDataCopy = [...state.filterData]
        setCombineData(filterDataCopy)
      } else {
        const universeDataCopy = [...state.universeData]
        setCombineData(universeDataCopy)
      }
    }
  }

  const onOptionsClick = () => {
    const filterData = [...state.filterData];
    const universeData = [...state.universeData];
    const questionOptions = { ...state.questionOptions };
    const newQuestionOptions = { ...state.newQuestionOptions };
    const selectedFilterOrUniverseData = [...state.selectedFilterOrUniverseData]

    dispatch({
      type: "SET_CHART_INITIAL_OPTIONS_DATA",
      payload: {
        initialFilterData: filterData,
        initialUniverseData: universeData,
        initialQuestionOptions: questionOptions,
        initialNewQuestionOptions: newQuestionOptions,
        initialSelectedFilterOrUniverseData: selectedFilterOrUniverseData
      }
    });

    openOptionsDialog();
  }

  const handleEditQuestion = (questions) => {
    dispatch({ type: 'CHART_EDIT_QUESTION', payload: { displayTable: state.displayTable, firstColumn: questions.firstColumn, scndColumn: questions.scndColumn } })
    setShowQuestionEditorModal(false);
  }
  const onAddToReport = () => {
    let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
    if (datasetType === 'surveys') {
      dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
    }
    const reportQuestionsData = state.firstColumn.filter((item) => item.selected)
    const reportColumnData = reportQuestionsData.map(item => {
      return {
        body: returnUpdatedAnalysisBody(params.analysisType, 'g', state.newQuestionOptions, state.firstColumn, state.scndColumn.filter((item) => !item.disabled), item.id),
        dataUrl: dataUrl,
        icon: "fas fa-chart-bar fa-sm"
      }
    })
    dispatch({ type: 'SET_REPORT_COLUMN_DATA', payload: { data: reportColumnData } });
    dispatch({ type: 'SET_REPORT_QUESTIONS_DATA', payload: { data: reportQuestionsData.map(item => { return { ...item, id: uuid(), type: "chart", active: false, selected: false } }) } })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The table has been added to Report' } });
  }

  const onActionHandler = (action) => {
    const updatedChartState = JSON.parse(JSON.stringify(state))
    const updatedChartQuestions = JSON.parse(JSON.stringify(updatedChartState.firstColumn))
    const updatedChartQuestionsColumns = JSON.parse(JSON.stringify(updatedChartState.scndColumn))

    if (action === 'enable') {
      updatedChartQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedChartQuestions.forEach((item) => {
        item.selected = false
      })
      updatedChartQuestionsColumns.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedChartQuestionsColumns.forEach((item) => {
        item.selected = false
      })

      dispatch({ type: 'CHART_ENABLE_SELECTED_QUESTIONS', payload: { firstColumn: updatedChartQuestions, scndColumn: updatedChartQuestionsColumns } })
    } else if (action === 'disable') {
      updatedChartQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedChartQuestions.forEach((item) => {
        item.selected = false
      })
      updatedChartQuestionsColumns.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedChartQuestionsColumns.forEach((item) => {
        item.selected = false
      })
      dispatch({ type: 'CHART_DISABLE_SELECTED_QUESTIONS', payload: { firstColumn: updatedChartQuestions, scndColumn: updatedChartQuestionsColumns } })
    } else if (action === 'weight') {
      setShowWeightWizard(true)
    } else if (action === 'select rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = true)
      dispatch({ type: 'CHART_UPDATE_ROWS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = false)
      dispatch({ type: 'CHART_UPDATE_ROWS', payload: rows })
    } else if (action === 'select columns') {
      const columns = state.scndColumn
      columns.map(e => e.selected = true)
      dispatch({ type: 'CHART_UPDATE_COLUMNS', payload: columns })
    } else if (action === 'deselect columns') {
      const columns = state.scndColumn
      columns.map(e => e.selected = false)
      dispatch({ type: 'CHART_UPDATE_COLUMNS', payload: columns })
    } else if (action === 'undo') {
      const updatedUndoQuestionOptions = [...undoQuestionOptions]
      const itemToAdd = undoQuestionOptions[undoQuestionOptions.length - 1]
      updatedUndoQuestionOptions.pop()
      dispatch({
        type: 'CHART_UNDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          undoQuestionOptions: updatedUndoQuestionOptions,
          redoQuestionOptions: [...redoQuestionOptions, state.questionOptions]
        }
      })
    } else if (action === 'redo') {
      const updatedRedoQuestionOptions = [...redoQuestionOptions]
      const itemToAdd = redoQuestionOptions[redoQuestionOptions.length - 1]
      updatedRedoQuestionOptions.pop()
      dispatch({
        type: 'CHART_REDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          redoQuestionOptions: updatedRedoQuestionOptions,
          undoQuestionOptions: [...undoQuestionOptions, state.questionOptions],
        }
      })
    } else if (action === 'copy xml') {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/adoc`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/adoc`
      }
      fetchCopyXML(dataUrl, token, returnUpdatedAnalysisBody(params.analysisType, 'g', state.newQuestionOptions, state.firstColumn, []))
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            res.text().then(data => {
              returnBrowser() === 'Safari' ?
                setShowCopyXmlModal({ show: true, xmlCode: data })
                :
                navigator.clipboard.writeText(data).then(() => {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } })
                });
            })
            setErrorMessage(null)
          }
        })
    } else if (action === 'copy-row-column') {
      copyAnalysisQuestions(state, dispatch, 'chart');
    } else if (action === 'paste-row-column') {
      pasteAnalysisQuestions(state, copyData, dispatch, 'chart');
    }
    setDidMount(true)
    setRefreshTable(true)
  }

  const exportFunc = (props) => {
    if (state.firstColumn && state.firstColumn.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const onAddToReports = () => {
    setShowAddReportsModal(true);
  }

  const updateReportItems = [{
    actionName: 'Save updates',
    action: 'saveUpdates'
  },
  {
    actionName: 'Clear edits',
    action: 'clearEditMode'
  }];

  const onUpdateReport = (action) => {
    switch (action) {
      case "saveUpdates": {
        if (reportsData.selectedReportFolder) {
          fetchPatchResOrJson(`an/projects/${projectId}/analysis/${datasetId}/reportfolders/${reportsData.selectedReportFolder}/reportSnapshots/${state.id}`, token, state)
            .then((res) => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
              } else {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Report updated" } });
              }
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Error updating report" } });
        }
        break;
      }
      case "clearEditMode":
        setDidMountSession(true);
        dispatch(updateEditReport(false));
        break;
    }
  }

  const actionItemRender = (item) => {
    return (
      <ExportActionItem
        user={user}
        props={{ item }}
        state={state}
        datasetId={datasetId}
        history={history}
        token={token}
        disabled={allColumnsDisabled}
        showExportModal={(e) => exportFunc(e)}
        tab='g' />
    )
  }

  const setLanguageError = (err) => {
    setErrorMessage(err)
  }

  return (
    <React.Fragment>
      {
        showQuestionEditorModal &&
        <QuestionEditor
          questions={state}
          source={state.selectedQuestionSource}
          handleClose={() => setShowQuestionEditorModal(false)}
          handleEditQuestion={handleEditQuestion}
        />
      }
      {showOptionsModal &&
        <OptionsDialog
          tab='chart'
          firstColumn={state.firstColumn}
          initialFirstColumn={JSON.parse(JSON.stringify(state.firstColumn))}
          activeGroup={optionsData.selectedGroup}
          data={optionsData.data}
          dropdownValues={optionsData.dropdownValues}
          defaultQuestionOptions={state.defaultQuestionOptions}
          values={state.questionOptions}
          updateValues={state.newQuestionOptions}
          expandedOptions={expandedOptions}
          optionGroups={optionsData.groups}
          handleClose={handleOptionsDialogClose}
          handleUpdate={handleOptionsUpdate}
          setCombineFilterData={setCombineFilterData}
          userData={userData}
          datasetId={datasetId}
          combineFilterData={combineData}
          user={user}
          token={token}
          analysisType={datasetType}
          optionId={state.optionId}
          initialOptionsData={state.initialOptionsData}
          initialAndUpdateOptionsValues={state.initialAndUpdateOptionsValues}
          onApplyWeightSet={(value) => onApplyWeightSet(value, state)}
        />
      }
      {
        showExportModal?.show &&
        <ExportModal
          onHide={() => { setShowExportModal(false); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          showExportModal={showExportModal}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onExportHandler={returnActionsData}
          state={state}
          updatedLanguage={editingLanguage}
          defaultLanguage={defaultLanguage}
          projectType={params.analysisType}
          datasetId={datasetId}
          history={history}
          tab={'g'}
          datasetName={datasetName}
          datasetType={datasetType}
          token={token} />
      }
      {showWeightWizard &&
        <WeightWizard
          handleClose={() => setShowWeightWizard(false)}
          token={token}
          datasetId={datasetId}
          user={user}
        />
      }
      {
        showAddReportsModal &&
        <AddReportsModal setShowAddReportsModal={setShowAddReportsModal} dataState={state} type={"g"} />
      }
      {
        (user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ?
          <UpgradePlanMessage className="tab-overlay" user={user} />
          : null
      }
      {showCopyXmlModal.show && <CopyXmlModal xmlCode={showCopyXmlModal.xmlCode} onHide={() => setShowCopyXmlModal({ show: false, xmlCode: '' })} />}
      <div className={`d-flex flex-column overflow-hidden flex-fill ${(user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ? 'blur' : null}`}>
        <div className={"justify-content-between flex-wrap d-flex border-left pr-1"}>
          <div className="btn-group m-2 analysis-actions">
            <Button
              disabled={!(state.checkedNum?.length && state.checkedNum.length > 0 && editingLanguage === defaultLanguage)}
              className={"btn btn-outline-analyze px-2"}
              onClick={() => setShowQuestionEditorModal(true)}
              title={"Edit"}>Edit
            </Button>
            {reportsData.isEditReport ?
              <DropDownButton
                buttonClass={"btn btn-outline-analyze px-2"}
                className='analyze-actions-button'
                icon="fas fa fa-caret-down"
                text="Update report"
                textField="actionName"
                items={updateReportItems}
                onItemClick={(props) => onUpdateReport(props.item.action)}
              />
              :
              <Button className={"btn btn-outline-analyze px-2"} onClick={onAddToReports}>
                Add to reports
              </Button>
            }
            <Button
              className={"btn btn-outline-analyze px-2"}
              disabled={!(checkedRows.length > 0) || reportsData.isEditReport}
              onClick={onAddToReport}>Add to report
            </Button>
            <div className="btn-group">
              <DropDownButton
                text={"Clear"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={clearQuestionItems}
                onItemClick={(props) => clearQuestions(props)}
                itemRender={(props) => (
                  <div className="p-1 dropdown-button__clear--big">
                    <span>
                      {props.item.actionName}
                    </span>
                  </div>
                )}
              />
            </div>
            <div className="btn-group">
              <DropDownButton
                text={"Actions"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={actionItems}
                onItemClick={(e) => onActionHandler(e.item.action)}
                popupSettings={{ popupClass: 'actions-dropdown' }}
              />
            </div>
            <Button
              className={"btn btn-outline-analyze px-2"}
              onClick={onOptionsClick}>Options
            </Button>
            {languages.length > 1 ?
              <div className='btn-group'>
                <LanguageSwitchButton
                  languages={languages}
                  editingLanguage={editingLanguage}
                  defaultLanguage={defaultLanguage}
                  tabType={"g"}
                  newQuestionOptions={state.newQuestionOptions}
                  firstColumn={state.firstColumn}
                  scndColumn={state.scndColumn.filter(item => !item.disabled)}
                  setLanguageError={setLanguageError}
                  token={token}
                  requestUrl={datasetType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}` : `an/projects/${projectId}/analysis/${datasetId}`}
                  dispatchType={'CHART_SET_TABLE_STATE'}
                  dataType={updateCrosstabData}
                  analysisBodyType={"chartTab"}
                  componentType={"chartTab"}
                />
              </div> : null}
          </div>

          <div className="btn-group m-2 analysis-actions">
            <ZoomButtons
              userSettings={userSettings}
              zoomType={'chartZoom'}
            />
            <DropdownButton
              hideChevron={true}
              className='btn-outline-analyze strong px-2'
              items={updatedItems}
              renderItem={actionItemRender}
              renderMainButton={() => (
                <span style={{ fontFamily: "Walr Inter", fontSize: "13px", fontWeight: 600 }} className='user-info'>Export</span>
              )}
            />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type='button'
                className="btn btn-outline-analyze btn-ic p-1"
                disabled={state?.firstColumn?.length === 0}
                title="Fullscreen"
                onClick={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: true })}>
                <Icon type="expand" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex overflow-hidden flex-fill border-table-area">
          <div className="w-100 d-flex">
            <div className='d-flex flex-column'>
              <DroppableColumn onCheck={onCheckQuestion} firstColumn={true} state={state} onSelectQuestion={onSelectQuestion} userData={userData} user={user} tab='chart' rangeItems={rangeItems} />
            </div>
            <div className="d-flex flex-column overflow-hidden flex-fill">
              <div className='d-flex flex-column flex-shrink-0'>
                <DroppableColumn onCheck={onCheckQuestion} secondColumn={true} state={state} userData={userData} user={user} tab='chart' rangeItems={rangeItems} />
              </div>
              <div className="w-100 d-flex flex-column overflow-auto p-4">
                {
                  showFullscreenResult &&
                  <ExpandResultModal
                    chartZoom={zoomLevel}
                    result={state.displayTable}
                    onHide={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: false })}
                  />
                }
                {
                  !errorMessage ?
                    <React.Fragment>
                      {
                        state.updateTable && state.firstColumn.length !== 0 ?
                          <InProgressOverlay theme="primary" type="fullscreen" />
                          : null
                      }
                      {
                        state.displayTable !== null && !state.updateTable ?
                          <ChartTypeContent
                            data={state.displayTable}
                            currentZoom={zoomLevel} />
                          : null
                      }
                    </React.Fragment>
                    :
                    <ErrorMessage type="alert" errorMessage={errorMessage} />
                }
              </div>
            </div>
          </div >
        </div >
      </div >
    </React.Fragment >
  )
}