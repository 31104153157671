import { type ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { fetchPatch, fetchPostJson } from "../../../../../../../../services/services";

type Props = {
  defaultLanguage: string
  checkedQuestions: TODO[]
  analysisType: string
  selectedQuestion: {
    id: string
    title: string
    groups: {
      code: number
    }[]
  }
  selectedCustomRow: {
    id: string
    code: number
    text: string
    customExpr: string
  }
  handleClose: () => void
  closeQuestionEditor: () => void
}

export const QuestionEditorAddCustomRowModal = ({ defaultLanguage, checkedQuestions, analysisType, selectedQuestion, selectedCustomRow, handleClose, closeQuestionEditor }: Props) => {
  const dispatch = useDispatch();
  const { name, dataset }: { name: string, dataset: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [formData, setFormData] = useState({
    code: selectedCustomRow ? selectedCustomRow.code : selectedQuestion.groups[selectedQuestion.groups.length - 1].code + 1,
    text: selectedCustomRow ? selectedCustomRow.text : '',
    expression: selectedCustomRow ? selectedCustomRow.customExpr : ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const selectedQuestionSource = checkedQuestions.find((question: TODO) => question.id === selectedQuestion.id);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, name: keyof typeof formData) => {
    const value = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const onAddCustomRowHandler = () => {
    setIsLoading(true);
    if (selectedCustomRow) {
      const body = { questionId: selectedQuestion.title, rows: [{ rowMiextId: selectedCustomRow.id, expression: formData.expression }] }
      fetchPatch(`an/projects/${name}/analysis/${dataset}/custom-rows`, token, body)
        .then((res: TODO) => res.json())
        .then((data: TODO) => {
          setIsLoading(false);
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } })
          } else {
            dispatch({ type: 'UPDATE_CUSTOM_ROW_IN_STRUCTURE', payload: { data: data, rowId: selectedCustomRow.id } });
            if (analysisType === 'crosstab') {
              dispatch({ type: 'XT_EDIT_CUSTOM_ROW_FROM_COLUMN', payload: { data: data, rowId: selectedCustomRow.id, questionInfo: selectedQuestionSource } });
            }
            handleClose();
            closeQuestionEditor();
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Custom row updated successfully' } });
          }
        })
    } else {
      const body = { questionId: selectedQuestion.title, rows: [{ ...formData }] };
      fetchPostJson(`an/projects/${name}/analysis/${dataset}/custom-rows`, token, body)
        .then((data: TODO) => {
          setIsLoading(false);
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } })
          } else {
            dispatch({ type: 'ADD_CUSTOM_ROW_TO_STRUCTURE', payload: data });
            if (analysisType === 'crosstab') {
              dispatch({ type: 'XT_ADD_CUSTOM_ROW_TO_COLUMN', payload: { data: data, questionInfo: selectedQuestionSource, defaultLanguage: defaultLanguage } });
            }
            handleClose();
            closeQuestionEditor();
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Custom row added successfully' } });
          }
        })
    }
  }

  return (
    <Dialog
      width={550}
      className="question-editor"
      onClose={handleClose}
      title={selectedCustomRow ? "Edit custom row" : "Add custom row"}>
      <div className="p-3 d-flex flex-column gap-md">
        <div className="form-group m-0">
          <label htmlFor="customRowCode">Code:</label>
          <input disabled={!!selectedCustomRow} value={formData.code} onChange={(e) => onChangeHandler(e, 'code')} className="form-control" type="number" id="customRowCode" />
        </div>
        <div className="form-group m-0">
          <label htmlFor="customRowText">Text:</label>
          <input disabled={!!selectedCustomRow} value={formData.text} onChange={(e) => onChangeHandler(e, 'text')} className="form-control" type="text" id="customRowText" />
        </div>
        <div className="form-group m-0">
          <label htmlFor="customRowExpression">Expression:</label>
          <input value={formData.expression} onChange={(e) => onChangeHandler(e, 'expression')} className="form-control" type="text" id="customRowExpression" />
        </div>
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button
          type='button'
          disabled={isLoading}
          className="k-button btn-analyze"
          onClick={onAddCustomRowHandler}>
          {
            isLoading &&
            <div className="spinner-border spinner-border-sm">
              <span className="sr-only">Loading...</span>
            </div>
          }
          {selectedCustomRow ? "Update" : "Add"}
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}