import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import JsxParser from 'react-jsx-parser';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Checkbox } from "@progress/kendo-react-inputs";

import items from '../exportItems.json';
import hierarchyActionItems from '../hierarchyActionItems.json';
import defaultOptions from '../../analysis/defaultOptionValues.json'
import { ExportModal } from '../../ExportModal/ExportModal';
import WeightWizard from '../../WeightWizard/WeightWizard';
import { OptionsDialog } from '../../OptionsDialog/OptionsDialog';
import { QuestionEditor } from '../../QuestionEditor/QuestionEditor';
import DroppableColumn from '../../../DroppableColumn/DroppableColumn';
import { AddToHierarchyModal } from '../../AddToHierarchyModal/AddToHierarchyModal';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons'
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import UpgradePlanMessage from '../../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import returnInitialOptions from '../../../../shared/helpers/returnInitialOptions/returnInitialOptions';
import setClearQuestonItems from '../../../../shared/helpers/setClearQuestionItems/setClearQuestonItems';
import { returnUpdatedAnalysisBody } from '../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import { returnUpdatedEditedHierarchyData } from '../../../../shared/helpers/returnUpdatedEditedHierarchyData/returnUpdatedEditedHierarchyData';
import returnCrosstabOptionsInitialValues from '../../../../shared/helpers/returnCrosstabOptionsInitialValues/returnCrosstabOptionsInitialValues';
import { returnMultiSelectionData } from '../../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { getUpdatedAnalysisOptions } from '../../../../shared/helpers/getUpdatedAnalysisOptions/getUpdatedAnalysisOptions';
import { returnAnalysisActionsItems } from '../../../../shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import { LanguageSwitchButton } from '../../../../shared/helpers/languageSwitchButton/LanguageSwitchButton';
import { fetchPost as fetchCopyXML, fetchPostJson as updateCrosstabData, fetchPatchResOrJson } from '../../../../../../../../services/services';
import { returnBrowser } from '../../../../shared/helpers/returnBrowser/returnBrowser';
import { CopyXmlModal } from '../../CopyXmlModal/CopyXmlModal';
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { ExpandResultModal } from '../../ExpandResultModal/ExpandResultModal';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import { AddReportsModal } from '../../Reports/Modals/AddReportsModal';
import { updateEditReport } from '../../../../../../../../store/reducers/analysisReportsStateReducer/analysisReportsStateReducer';
import { copyAnalysisQuestions } from '../../../../shared/helpers/copyAnalysisQuestions/copyAnalysisQuestions';
import { pasteAnalysisQuestions } from '../../../../shared/helpers/pasteAnalysisQuestions/pasteAnalysisQuestions';

export const CrossTableTabContent = ({ onAutoSaveHandler, activeTabs, setTabSelected, setOptionsData, userData, datasetName, optionsData, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, rangeItems, analysisFunctionalities, onApplyWeightSet, setDidMountSession }) => {
  const params = useParams();
  const { token } = useSelector((state) => state.tokenStateReducer);
  const history = useHistory()
  const dispatch = useDispatch();

  const projectId = params.name;
  const datasetId = params.dataset;
  const datasetType = params.analysisType;

  const reportsData = useSelector((theState) => theState.analysisReportsReducer);
  const [showQuestionEditorModal, setShowQuestionEditorModal] = useState(false);
  const [showAddHierarchyModal, setShowAddHierarchyModal] = useState(false)
  const [showEditHierarchyModal, setShowEditHierarchyModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState({ show: false, type: null, extension: null })
  const [combineData, setCombineData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [showWeightWizard, setShowWeightWizard] = useState(false)
  const { theData, defaultLanguage, languages, editingLanguage, showFullscreenResult, copyData } = useSelector((theState) => (theState.setInitialDataReducer));
  const { state, undoQuestionOptions, redoQuestionOptions } = useSelector((theState) => (theState.stateReducer));
  const [showCopyXmlModal, setShowCopyXmlModal] = useState({ show: false, xmlCode: '' })

  const userSettings = useSelector(theState => theState.userSettingsReducer)
  const zoomLevel = useSelector(theState => theState.userSettingsReducer.zoomLevels.crossTableZoom)
  const prevUpdateTable = state ? usePrevious(state.updateTable) : null

  const allColumnsDisabled = state.firstColumn.filter(e => !e.disabled).length === 0
  const [refreshTable, setRefreshTable] = useState(false)
  const [checkedColumns, setCheckedColumns] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [didMount, setDidMount] = useState(true)
  const [freezeHeader, setFreezeHeader] = useState(false);
  const [freezeColumn, setFreezeColumn] = useState(false);
  const [showAddReportsModal, setShowAddReportsModal] = useState(false);
  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType) //export dropdown
  const actionItems = returnAnalysisActionsItems(undoQuestionOptions, redoQuestionOptions, false, datasetType, !state.firstColumn.length, state.checkedNum, copyData.elements) // actions dropdown
  const updatedActionItems = [
    ...actionItems,
    { actionName: "Copy to Chart", action: "copyToChart", iconClass: "fas fa-copy mr-3 py-2" },
    { disabled: state.firstColumn.filter(el => el.selected).length < 2, actionName: "Group selected questions", action: "group questions", iconClass: "fas fa-layer-plus mr-3 py-2" },
    { disabled: state.firstColumn.filter(el => el.selected).length === 1 && state.firstColumn.find(el => el.selected)?.type === 'group' ? false : true, actionName: "Ungroup selected question", action: "ungroup questions", iconClass: "fas fa-layer-minus mr-3 py-2" }
  ]

  useEffect(() => {
    dispatch({ type: "SET_UPDATE_TABLE_STATE", payload: { status: true } })
  }, [dispatch])

  useEffect(() => {
    const checkedRowsFiltered = state.firstColumn.filter((item) => item.selected).map(el => el.id)
    const checkedColumnsFiltered = state.scndColumn.filter((item) => item.selected).map(el => el.id)
    if (didMount) {
      const checkedRowsNums = checkedRowsFiltered.map(e => ({ source: 'firstColumn', id: e }))
      const checkedColumnsNums = checkedColumnsFiltered.map(e => ({ source: 'scndColumn', id: e }))
      const combinedNums = checkedRowsNums.concat(checkedColumnsNums)
      dispatch({ type: 'XT_UPDATE_CHECKED_NUM', payload: combinedNums })
      setDidMount(false)
    }
    setCheckedColumns(checkedColumnsFiltered)
    setCheckedRows(checkedRowsFiltered)
  }, [state.firstColumn, state.scndColumn, didMount, dispatch])

  useEffect(() => {
    if ((state.updateTable && state.updateTable !== prevUpdateTable) || refreshTable) {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}`
      }

      const body = returnUpdatedAnalysisBody(params.analysisType, 'xt', state.newQuestionOptions, state.firstColumn, state.scndColumn.filter(item => !item.disabled), '', editingLanguage, defaultLanguage)
      updateCrosstabData(dataUrl, token, body)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.error)
            dispatch({ type: 'SET_TABLE_STATE', payload: { value: null } })
          } else {
            const updatedResult = res.result.replace(/{/g, "&#123;").replace(/}/g, "&#125;");
            dispatch({ type: 'SET_TABLE_STATE', payload: { value: updatedResult } })
            setErrorMessage(null)
          }
        })
      onAutoSaveHandler(state)
      setRefreshTable(false)
    }
  }, [state.updateTable, state.newQuestionOptions, state.firstColumn, dispatch, state.scndColumn, refreshTable, datasetId, token, onAutoSaveHandler, prevUpdateTable, history.location.pathname, datasetType, editingLanguage, params.analysisType, defaultLanguage])

  const clearQuestions = (props) => {
    if (props.item.action === "CLEAR_QUESTION_OPTIONS") {
      const crosstabValues = returnCrosstabOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[1].crosstab, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: crosstabValues, options: initialOptions } })

    } else if (props.item.action === "CLEAR_QUESTIONS") {
      const crosstabValues = returnCrosstabOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[1].crosstab, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: crosstabValues, options: initialOptions } })
    } else if (state.firstColumn.length > 0 || state.scndColumn.length > 0) {
      dispatch({ type: props.item.action })
      setErrorMessage(null)
    }
  }

  const onHierarchyActionHandler = (e) => {
    if (e.item.action === 'add') {
      setShowAddHierarchyModal(true)
    } else {
      const stateCopy = { ...state }
      if (stateCopy.checkedNum?.length > 0) {
        const checkedElement = stateCopy[stateCopy.checkedNum[0].source].find(el => el.id === stateCopy.checkedNum[0].id)
        if (checkedElement && checkedElement.hierarchy?.length > 0) {
          if (e.item.action === 'edit') {
            setShowEditHierarchyModal(true)
          } else if (e.item.action === 'clear') {
            checkedElement.hierarchy = []
            dispatch({ type: 'ADD_HIERARCHY', payload: { source: stateCopy.selectedQuestionSource, column: stateCopy[stateCopy.selectedQuestionSource] } })
          }
        }
      }
    }
  }

  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'SELECT_QUESTION', payload: { id: id, theData: theData } })
  }

  const onCheckQuestion = (e, id, source, itemId, shouldDefineRange) => {
    if (source === "firstColumn") {
      const updatedColumnsData = returnMultiSelectionData(state.firstColumn, checkedRows, shouldDefineRange, itemId, (data) => setCheckedColumns(data), 'selected')
      dispatch({ type: 'XT_UPDATE_ROWS', payload: updatedColumnsData })
    } else {
      const updatedRowsData = returnMultiSelectionData(state.scndColumn, checkedColumns, shouldDefineRange, itemId, (data) => setCheckedRows(data), 'selected')
      dispatch({ type: 'XT_UPDATE_COLUMNS', payload: updatedRowsData })
    }
    setDidMount(true)

  }

  const handleOptionsUpdate = (newValues, values, data, option) => {
    if (state.firstColumn.some(ques => ques.selected)) {
      const updatedFirstColumn = [...state.firstColumn]
      const selectedQuestions = updatedFirstColumn.filter(el => el.selected)

      selectedQuestions.forEach(selectedQuestion => {
        const checkedQuestionIndex = updatedFirstColumn.findIndex((question) => question.id === selectedQuestion.id)
        const updatedValues = Object.assign({}, updatedFirstColumn[checkedQuestionIndex].options, newValues)

        updatedFirstColumn[checkedQuestionIndex].options = updatedValues;
      })

      dispatch({ type: 'SET_CHECKED_QUESTION_OPTIONS', payload: updatedFirstColumn })
    } else {
      const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, newValues)
      dispatch({ type: 'SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: values } })
    }

    const updatedOptionsWithInitialValue = getUpdatedAnalysisOptions(state, values)
    dispatch({ type: 'UPDATE_XT_UNDO_QUESTION_OPTIONS', payload: [...undoQuestionOptions, updatedOptionsWithInitialValue] })

    handleOptionsDialogClose()
  }

  const handleEditQuestion = (questions, editHierarchy) => {
    if (editHierarchy) {
      const updatedEditedHierarchyData = returnUpdatedEditedHierarchyData(state, questions)
      if (updatedEditedHierarchyData.questionToBeReplaced && updatedEditedHierarchyData.stateCopy) {
        dispatch({ type: 'EDIT_HIERARCHY', payload: { firstColumn: updatedEditedHierarchyData.stateCopy.firstColumn, scndColumn: updatedEditedHierarchyData.stateCopy.scndColumn } })
        setShowEditHierarchyModal(false)
      }
    } else {
      dispatch({ type: 'EDIT_QUESTION', payload: { firstColumn: questions.firstColumn, scndColumn: questions.scndColumn } })
      setShowQuestionEditorModal(false);
    }
  }

  const setCombineFilterData = (id) => {
    const firstColumnCopy = [...state.firstColumn];
    const selectedQuestions = firstColumnCopy.filter(el => el.selected);

    if (selectedQuestions.length > 0) {
      const selectedFilterOrUniverseDataCopy = [...state.selectedFilterOrUniverseData];
      setCombineData(selectedFilterOrUniverseDataCopy);
    } else {
      if (id === "XtFilterCode") {
        const filterDataCopy = [...state.filterData]
        setCombineData(filterDataCopy)
      } else {
        const universeDataCopy = [...state.universeData]
        setCombineData(universeDataCopy)
      }
    }
  }

  const onOptionsClick = () => {
    const filterData = [...state.filterData];
    const universeData = [...state.universeData];
    const questionOptions = { ...state.questionOptions };
    const newQuestionOptions = { ...state.newQuestionOptions };
    const selectedFilterOrUniverseData = [...state.selectedFilterOrUniverseData]

    dispatch({
      type: "SET_INITIAL_OPTIONS_DATA",
      payload: {
        initialFilterData: filterData,
        initialUniverseData: universeData,
        initialQuestionOptions: questionOptions,
        initialNewQuestionOptions: newQuestionOptions,
        initialSelectedFilterOrUniverseData: selectedFilterOrUniverseData
      }
    });

    openOptionsDialog();
  }

  const clearQuestionItems = setClearQuestonItems();

  const exportFunc = (props) => {
    if (state.firstColumn && state.firstColumn.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (item) => {
    return (
      <ExportActionItem
        user={user}
        props={{ item }}
        state={state}
        datasetId={datasetId}
        history={history}
        token={token}
        disabled={allColumnsDisabled}
        showExportModal={(e) => exportFunc(e)}
        tab='xt' />
    )
  }

  const onMoveToChartHandler = () => {
    let scriptTabIndex = 2
    if (activeTabs.find(el => el.id === 'Chart')) {
      scriptTabIndex = activeTabs.indexOf(activeTabs.find(el => el.id === 'Chart'))
    }
    const updatedOptionsData = { ...optionsData }
    updatedOptionsData.shouldUpdateOptions = true
    dispatch({ type: 'MOVE_DATA_TO_CHART', payload: { firstColumn: state.firstColumn, scndColumn: state.scndColumn, updatedOptions: state.newQuestionOptions } })
    setOptionsData(updatedOptionsData)
    setTabSelected(scriptTabIndex)
  }
  const onAddToReport = () => {
    let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
    if (datasetType === 'surveys') {
      dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
    }
    const reportQuestionsData = state.firstColumn.filter((item) => item.selected)
    const reportColumnData = reportQuestionsData.map(item => {
      return {
        body: returnUpdatedAnalysisBody(params.analysisType, 'xt', state.newQuestionOptions, state.firstColumn, state.scndColumn, item.id),
        dataUrl: dataUrl,
        icon: "fa fa-table fa-sm"
      }
    })
    dispatch({ type: 'SET_REPORT_COLUMN_DATA', payload: { data: reportColumnData } });
    dispatch({ type: 'SET_REPORT_QUESTIONS_DATA', payload: { data: reportQuestionsData.map(item => { return { ...item, id: uuid(), type: "crosstab", active: false, selected: false } }) } })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The cross table result has been added to report successfully.' } });
  }

  const onActionHandler = (action) => {
    const updatedXTState = JSON.parse(JSON.stringify(state))
    const updatedXTQuestions = JSON.parse(JSON.stringify(updatedXTState.firstColumn))
    const updatedXTQuestionsColumns = JSON.parse(JSON.stringify(updatedXTState.scndColumn))

    if (action === 'enable') {
      updatedXTQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedXTQuestions.forEach((item) => {
        item.selected = false
      })
      updatedXTQuestionsColumns.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedXTQuestionsColumns.forEach((item) => {
        item.selected = false
      })

      dispatch({ type: 'XT_ENABLE_SELECTED_QUESTIONS', payload: { firstColumn: updatedXTQuestions, scndColumn: updatedXTQuestionsColumns } })
    } else if (action === 'disable') {
      updatedXTQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedXTQuestions.forEach((item) => {
        item.selected = false
      })
      updatedXTQuestionsColumns.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedXTQuestionsColumns.forEach((item) => {
        item.selected = false
      })
      dispatch({ type: 'XT_DISABLE_SELECTED_QUESTIONS', payload: { firstColumn: updatedXTQuestions, scndColumn: updatedXTQuestionsColumns } })
    } else if (action === 'weight') {
      setShowWeightWizard(true)
    } else if (action === 'select rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = true)
      dispatch({ type: 'XT_UPDATE_ROWS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = false)
      dispatch({ type: 'XT_UPDATE_ROWS', payload: rows })
    } else if (action === 'select columns') {
      const columns = state.scndColumn
      columns.map(e => e.selected = true)
      dispatch({ type: 'XT_UPDATE_COLUMNS', payload: columns })
    } else if (action === 'deselect columns') {
      const columns = state.scndColumn
      columns.map(e => e.selected = false)
      dispatch({ type: 'XT_UPDATE_COLUMNS', payload: columns })
    } else if (action === 'copyToChart') {
      onMoveToChartHandler()
    } else if (action === 'undo') {
      const updatedUndoQuestionOptions = [...undoQuestionOptions]
      const itemToAdd = undoQuestionOptions[undoQuestionOptions.length - 1]
      updatedUndoQuestionOptions.pop()
      dispatch({
        type: 'XT_UNDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          undoQuestionOptions: updatedUndoQuestionOptions,
          redoQuestionOptions: [...redoQuestionOptions, state.questionOptions]
        }
      })
    } else if (action === 'redo') {
      const updatedRedoQuestionOptions = [...redoQuestionOptions]
      const itemToAdd = redoQuestionOptions[redoQuestionOptions.length - 1]
      updatedRedoQuestionOptions.pop()
      dispatch({
        type: 'XT_REDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          redoQuestionOptions: updatedRedoQuestionOptions,
          undoQuestionOptions: [...undoQuestionOptions, state.questionOptions],
        }
      })
    } else if (action === 'copy-row-column') {
      copyAnalysisQuestions(state, dispatch, 'xt');
    } else if (action === 'paste-row-column') {
      pasteAnalysisQuestions(state, copyData, dispatch, 'xt');
    } else if (action === 'copy xml') {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/adoc`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/adoc`
      }
      fetchCopyXML(dataUrl, token, returnUpdatedAnalysisBody(params.analysisType, 'xt', state.newQuestionOptions, state.firstColumn, state.scndColumn))
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            res.text().then(data => {
              returnBrowser() === 'Safari' ?
                setShowCopyXmlModal({ show: true, xmlCode: data })
                :
                navigator.clipboard.writeText(data).then(() => {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } })
                });
            })
            setErrorMessage(null)
          }
        })
    } else if (action === 'group questions') {
      const selectedQuestions = updatedXTQuestions.filter((item) => item.selected)
      if (selectedQuestions.length > 1) {
        let newGroupedQuestion = {
          id: uuid(),
          type: 'group',
          active: true,
          selected: false
        }
        if (selectedQuestions.some(el => el.type === 'group')) {
          const firstSelectedGroupQuestion = selectedQuestions.find(el => el.type === 'group')
          let otherQuestions = []
          selectedQuestions.filter(el => el.id !== firstSelectedGroupQuestion.id).forEach(el => {
            if (el.type === 'group') {
              otherQuestions = [...otherQuestions, ...el.questions]
            } else {
              otherQuestions.push(el)
            }
          })
          newGroupedQuestion = {
            ...newGroupedQuestion,
            content: firstSelectedGroupQuestion.content,
            title: selectedQuestions.map(el => el.title).join(', '),
            questions: [...firstSelectedGroupQuestion.questions, ...otherQuestions],
          }
        } else {
          newGroupedQuestion = {
            ...newGroupedQuestion,
            content: selectedQuestions[0].content,
            title: selectedQuestions.map(el => el.title).join(', '),
            questions: selectedQuestions,
          }
        }
        if (newGroupedQuestion.questions) {
          updatedXTState.firstColumn = updatedXTQuestions.filter((item) => !item.selected)
          updatedXTState.firstColumn.push(newGroupedQuestion)
          updatedXTState.updateTable = true
          dispatch({ type: 'CREATE_NEW_GROUPED_QUESTION', payload: updatedXTState })
        }
      }
    } else if (action === 'ungroup questions') {
      const selectedGroupQuestion = updatedXTQuestions.find(el => el.selected && el.type === 'group')
      if (selectedGroupQuestion) {
        updatedXTState.firstColumn = updatedXTQuestions.filter((item) => !item.selected)
        updatedXTState.firstColumn.push(...selectedGroupQuestion.questions)
        updatedXTState.updateTable = true
        dispatch({ type: 'UNGROUP_QUESTION', payload: updatedXTState })
      }
    }

    setDidMount(true)
    if (action !== 'copy xml' && action !== 'copy-row-column' && action !== 'paste-row-column') {
      setRefreshTable(true)
    }
  }

  const setLanguageError = (err) => {
    setErrorMessage(err)
  }

  const editClickHandler = () => {
    let doesCheckedIncludeAGroupedQuestion = false;
    for (let i = 0; i < state.checkedNum.length; i++) {
      const el = state.checkedNum[i];
      const item = state[el.source].find(item => item.id === el.id);
      if (item.type === 'group') {
        doesCheckedIncludeAGroupedQuestion = true;
        break;
      }
    }
    if (doesCheckedIncludeAGroupedQuestion) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Editing a grouped question is currently not supported.' } });
    } else {
      setShowQuestionEditorModal(true);
    }
  }

  const onAddToReports = () => {
    setShowAddReportsModal(true);
  }

  const updateReportItems = [{
    actionName: 'Save updates',
    action: 'saveUpdates'
  },
  {
    actionName: 'Clear edits',
    action: 'clearEditMode'
  }];

  const onUpdateReport = (action) => {
    switch (action) {
      case "saveUpdates": {
        if (reportsData.selectedReportFolder) {
          fetchPatchResOrJson(`an/projects/${projectId}/analysis/${datasetId}/reportfolders/${reportsData.selectedReportFolder}/reportSnapshots/${state.id}`, token, state)
            .then((res) => {
              if (res.error || res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
              } else {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Report updated" } });
              }
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Error updating report" } });
        }
        break;
      }
      case "clearEditMode":
        setDidMountSession(true);
        dispatch(updateEditReport(false));
        break;
    }
  }

  return (
    <React.Fragment>
      {
        showAddHierarchyModal &&
        <AddToHierarchyModal
          state={state}
          handleClose={() => setShowAddHierarchyModal(false)} />
      }
      {
        showEditHierarchyModal &&
        <QuestionEditor
          questions={JSON.parse(JSON.stringify(state))}
          editHierarchy={true}
          allQuestions={theData}
          source={state.selectedQuestionSource}
          handleEditQuestion={handleEditQuestion}
          handleClose={() => setShowEditHierarchyModal(false)} />
      }
      {
        showQuestionEditorModal &&
        <QuestionEditor
          questions={state}
          analysisType='crosstab'
          source={state.selectedQuestionSource}
          handleClose={() => setShowQuestionEditorModal(false)}
          handleEditQuestion={handleEditQuestion}
        />
      }
      {
        showOptionsModal &&
        <OptionsDialog
          tab='crosstab'
          firstColumn={state.firstColumn}
          initialFirstColumn={JSON.parse(JSON.stringify(state.firstColumn))}
          activeGroup={optionsData.selectedGroup}
          data={optionsData.data}
          dropdownValues={optionsData.dropdownValues}
          defaultQuestionOptions={state.defaultQuestionOptions}
          values={state.questionOptions}
          updateValues={state.newQuestionOptions}
          expandedOptions={expandedOptions}
          optionGroups={optionsData.groups}
          handleClose={handleOptionsDialogClose}
          handleUpdate={handleOptionsUpdate}
          setCombineFilterData={setCombineFilterData}
          userData={userData}
          datasetId={datasetId}
          combineFilterData={combineData}
          user={user}
          token={token}
          analysisType={datasetType}
          optionId={state.optionId}
          initialOptionsData={state.initialOptionsData}
          initialAndUpdateOptionsValues={state.initialAndUpdateOptionsValues}
          onApplyWeightSet={(value) => onApplyWeightSet(value, state)}
        />
      }
      {
        showExportModal?.show &&
        <ExportModal
          onHide={() => { setShowExportModal(false); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          showExportModal={showExportModal}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onExportHandler={returnActionsData}
          defaultLanguage={defaultLanguage}
          projectType={params.analysisType}
          state={state}
          datasetId={datasetId}
          history={history}
          updatedLanguage={editingLanguage}
          tab={'xt'}
          datasetName={datasetName}
          datasetType={datasetType}
          token={token} />
      }
      {showWeightWizard &&
        <WeightWizard
          handleClose={() => setShowWeightWizard(false)}
          token={token}
          datasetId={datasetId}
          user={user}
        />
      }
      {
        showAddReportsModal &&
        <AddReportsModal setShowAddReportsModal={setShowAddReportsModal} dataState={state} type={'xt'} />
      }
      {
        user.plan === 'basic' && !user.isSubAccount ?
          <UpgradePlanMessage className="tab-overlay" user={user} />
          : null
      }
      {showCopyXmlModal.show && <CopyXmlModal xmlCode={showCopyXmlModal.xmlCode} onHide={() => setShowCopyXmlModal({ show: false, xmlCode: '' })} />}
      <div className={`d-flex flex-column overflow-hidden flex-fill ${user.plan === 'basic' && !user.isSubAccount ? 'blur' : null}`}>
        <div className={"d-flex justify-content-between flex-wrap border-left pr-1"}>

          <div className="btn-group m-2 analysis-actions" role="group">
            <Button
              disabled={state.checkedNum?.length && state.checkedNum.length > 0 && editingLanguage === defaultLanguage ? false : true}
              className={"btn btn-outline-analyze px-2"}
              onClick={editClickHandler}>Edit
            </Button>
            {reportsData.isEditReport ?
              <DropDownButton
                buttonClass={"btn btn-outline-analyze px-2"}
                className='analyze-actions-button'
                icon="fas fa fa-caret-down"
                text="Update report"
                textField="actionName"
                items={updateReportItems}
                onItemClick={(props) => onUpdateReport(props.item.action)}
              />
              :
              <Button className={"btn btn-outline-analyze px-2"} onClick={onAddToReports}>
                Add to reports
              </Button>
            }
            <Button
              className={"btn btn-outline-analyze px-2"}
              disabled={!(checkedRows.length > 0) || reportsData.isEditReport}
              onClick={onAddToReport}>Add to report
            </Button>
            <div className="btn-group" role="group">
              <DropDownButton
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                className='analyze-actions-button'
                icon="fas fa fa-caret-down"
                text="Clear"
                textField="actionName"
                items={clearQuestionItems}
                onItemClick={(props) => clearQuestions(props)}
              />
            </div>
            <div className="btn-group" role="group">
              <DropDownButton
                text={"Actions"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                popupSettings={{ popupClass: 'actions-dropdown' }}
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={updatedActionItems}
                onItemClick={(e) => onActionHandler(e.item.action)}
              />
            </div>
            <div className="btn-group" role="group">
              <DropDownButton
                text="Hierarchy"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={hierarchyActionItems}
                disabled={state.checkedNum?.length !== 1}
                onItemClick={(e) => onHierarchyActionHandler(e, state)}
                itemRender={(props) => (
                  <div className="p-1">
                    <span className={`${(props.item.action === 'edit' || props.item.action === 'clear') && (!state[state.checkedNum[0].source].find(el => el.id === state.checkedNum[0].id).hierarchy || state[state.checkedNum[0].source].find(el => el.id === state.checkedNum[0].id).hierarchy?.length === 0) ? 'text-disabled' : ''}`}>
                      <i className={`${props.item.icon} mr-3`} />{props.item.actionName}
                    </span>
                  </div>
                )}
              />
            </div>
            <Button
              className={"btn btn-outline-analyze px-2"}
              onClick={onOptionsClick}>Options
            </Button>
            {languages.length > 1 ?
              <div className='btn-group' role='group'>
                <LanguageSwitchButton
                  languages={languages}
                  editingLanguage={editingLanguage}
                  defaultLanguage={defaultLanguage}
                  tabType={"xt"}
                  newQuestionOptions={state.newQuestionOptions}
                  firstColumn={state.firstColumn}
                  scndColumn={state.scndColumn.filter(item => !item.disabled)}
                  setLanguageError={setLanguageError}
                  token={token}
                  requestUrl={datasetType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}` : `an/projects/${projectId}/analysis/${datasetId}`}
                  dispatchType={'SET_TABLE_STATE'}
                  dataType={updateCrosstabData}
                  analysisBodyType={"crossTab"}
                  componentType={"crossTab"}
                />
              </div> : null}
          </div>
          <div className="btn-group m-2 analysis-actions" role="group">
            <ZoomButtons
              userSettings={userSettings}
              zoomType={'crossTableZoom'}
            />
            <DropdownButton
              hideChevron={true}
              className='btn-outline-analyze strong px-2'
              items={updatedItems}
              renderItem={actionItemRender}
              renderMainButton={() => (
                <span style={{ fontFamily: "Walr Inter", fontSize: "13px", fontWeight: 600 }} className='user-info'>Export</span>
              )}
            />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type='button'
                className="btn btn-outline-analyze btn-ic p-1"
                disabled={state?.firstColumn?.length === 0}
                title="Fullscreen"
                onClick={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: true })}>
                <Icon type="expand" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex overflow-hidden flex-fill border-table-area">
          <div className="w-100 d-flex crosstab">
            <div className='d-flex flex-column'>
              <DroppableColumn onCheck={onCheckQuestion} firstColumn={true} state={state} onSelectQuestion={onSelectQuestion} userData={userData} user={user} tab='crosstab' rangeItems={rangeItems} />
            </div>
            <div className="d-flex flex-column overflow-hidden flex-fill">
              <div className='d-flex flex-column flex-shrink-0'>
                <DroppableColumn onCheck={onCheckQuestion} secondColumn={true} state={state} userData={userData} user={user} tab='crosstab' />
              </div>
              <div className="w-100 h-100 d-flex flex-column overflow-hidden bg-white" style={{ fontSize: `${((zoomLevel) * 0.875).toString()}rem` }}>
                {
                  showFullscreenResult &&
                  <ExpandResultModal
                    result={state.displayTable}
                    onHide={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: false })}
                  />
                }
                {
                  !errorMessage ?
                    state.updateTable && state.firstColumn.length !== 0 ?
                      <InProgressOverlay theme="primary" type="fullscreen" />
                      :
                      <div className={`d-flex flex-column overflow-hidden bg-white gap-md ${freezeColumn ? "freeze-column" : ""} ${freezeHeader ? "freeze-header" : ""}`}>
                        <div className='d-flex gap-md px-4 py-1'>
                          <div className="d-flex flex-row gap-sm">
                            <label htmlFor='freezeHeader' className="d-flex gap-sm align-items-center">
                              <Checkbox id='freezeHeader' value={freezeHeader} onChange={() => setFreezeHeader(!freezeHeader)} />
                              Freeze header
                            </label>
                          </div>

                          <div className="d-flex flex-row gap-sm">
                            <label htmlFor="freezeColumn" className="d-flex gap-sm align-items-center">
                              <Checkbox id="freezeColumn" value={freezeColumn} onChange={() => setFreezeColumn(!freezeColumn)} />
                              Freeze column
                            </label>
                          </div>
                        </div>

                        <JsxParser jsx={state.displayTable} />
                      </div>
                    :
                    <ErrorMessage type="alert" errorMessage={errorMessage} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}