import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActionsButtons from './ActionButtons/ActionButtons';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { fetchPostJson } from '../../../../../../../../services/services';
import CustomAlert from './CustomAlert/CustomAlert';
import ExistingAdvancedWeights from './ExistingAdvancedWeight';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

const TOTAL_STEPS = 6;

const isWeightNameValid = (weightName: string): boolean => {
  const regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(weightName);
};

interface AdvancedWeightingWizardProps {
  token: string;
  datasetId: string;
  onClose: () => void;
  isExisting?: boolean;
}

interface AlertProps {
  type: 'SUCCESS' | 'INFO' | 'ERROR';
  message: string | string[];
  autoDismiss?: number;
}

const AdvancedWeightingWizard = ({
  token,
  datasetId,
  onClose,
  isExisting,
}: AdvancedWeightingWizardProps) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[2];

  const [currentStep, setCurrentStep] = useState<number>(isExisting ? 0 : 1);
  const [universeDescription, setUniverseDescription] = useState<string>('');
  const [universeDefinition, setUniverseDefinition] = useState<{ combineData: TODO[], expression: string }>({ combineData: [], expression: '' });
  const [weightName, setWeightName] = useState<string>('');
  const [weightDescription, setWeightDescription] = useState<string>('');
  const [createUniverse, setCreateUniverse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mainTemplateData, setMainTemplateData] = useState<TemplateData | null>(null);
  const [weightResult, setWeightResult] = useState<WeightResult>();
  const [roundingValues, setRoundingValues] = useState<Rounding>({
    enabled: false,
    decimals: 0,
    roundingDirection: 0,
    roundingErrorDistribution: 0,
  });
  const [shouldValidate, setShouldValidate] = useState<boolean>(!isExisting);
  const [capWeights, setCapWeights] = useState<{
    [categoryText: string]: { minCap: number | null; maxCap: number | null };
  }>({});
  const [maxIterations, setMaxIterations] = useState<number | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const [showValidationWarning, setShowValidationWarning] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<'next' | 'back' | null>(null);

  const handleAlertClose = () => {
    setAlert(null);
  };

  const handleNext = async (): Promise<void> => {
    if (currentStep === 2) {
      if (shouldValidate) {
        setShowValidationWarning(true);
        setPendingAction('next');
        return;
      }
      if (mainTemplateData) {
        setCurrentStep(3);
      }
      return;
    }

    switch (currentStep) {
      case 1:
        if (isWeightNameValid(weightName)) {
          setCurrentStep(2);
        } else {
          setAlert({
            type: 'ERROR',
            message:
              'Weight name contains illegal characters. Allowed characters are a-z, A-Z, _ and 0-9',
          });
        }
        break;
      case 3:
        setCurrentStep(4);
        break;
      case 4:
        setCurrentStep(5);
        break;
      case 5:
        if (mainTemplateData) {
          await createWeightDryRun();
        }
        break;
      case 6:
        if (weightResult) {
          await createWeight();
        }
        break;
      default:
        if (currentStep < TOTAL_STEPS) {
          setCurrentStep(currentStep + 1);
        }
        break;
    }
  };

  const handleNextDisabled = (): boolean => {
    switch (currentStep) {
      case 1:
        return !weightName || isLoading;
      case 2:
        return !mainTemplateData || isLoading;
      default:
        return isLoading;
    }
  };

  const isNextDisabled = handleNextDisabled();

  const handleBack = (): void => {
    if (currentStep === 2) {
      if (shouldValidate) {
        setShowValidationWarning(true);
        setPendingAction('back');
        return;
      }
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
      }
      return;
    }
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleValidationWarningConfirm = (): void => {
    setShowValidationWarning(false);
    if (pendingAction === 'next') {
      setShouldValidate(false);
      if (mainTemplateData) {
        setCurrentStep(3);
      }
    } else if (pendingAction === 'back') {
      setShouldValidate(false);
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
      }
    }
    setPendingAction(null);
  };

  const handleValidationWarningCancel = (): void => {
    setShowValidationWarning(false);
    setPendingAction(null);
  };

  const validateTemplateData = async (template: TemplateData) => {
    setAlert(null);
    setIsLoading(true);
    const payload = { ...template, id: weightName, description: weightDescription };
    try {
      const response = await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/validate`,
        token,
        payload
      );

      if (Array.isArray(response) && response.length > 0) {
        throw response;
      }
      setIsLoading(false);
      setShouldValidate(false);
      setAlert({
        type: 'SUCCESS',
        message: 'Template Validated',
        autoDismiss: 5000,
      });
      return { validate: true };
    } catch (error) {
      setIsLoading(false);
      const errorMsg = Array.isArray(error) ? error : [error || 'Unable to validate data'];
      setAlert({
        type: 'ERROR',
        message: errorMsg,
      });
      throw error;
    }
  };

  const createWeightDryRun = async () => {
    setAlert(null);
    setIsLoading(true);
    const updatedTemplateData = { ...mainTemplateData };
    if (updatedTemplateData?.subGroup?.categories) {
      updatedTemplateData.subGroup.categories = updatedTemplateData.subGroup.categories.map(
        (category) => {
          const cap = capWeights[category.text];
          return {
            ...category,
            minCap: cap ? cap.minCap : null,
            maxCap: cap ? cap.maxCap : null,
          };
        }
      );
    }

    const payload = {
      ...updatedTemplateData,
      id: weightName,
      description: weightDescription,
      settings: {
        ...updatedTemplateData?.settings,
        rounding: roundingValues,
        maxIterations: maxIterations ?? updatedTemplateData?.settings?.maxIterations,
      },
    };
    try {
      setAlert(null);
      const response = await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/dry-run`,
        token,
        payload
      );
      setWeightResult(response);

      if (response.overallStats) {
        setAlert({
          type: 'SUCCESS',
          message: 'Dry run successful',
          autoDismiss: 5000,
        });
        setCurrentStep(6);
      } else if (Array.isArray(response) && response.length > 0) {
        throw response;
      } else if (response.error) {
        throw response.error;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMsg = Array.isArray(error) ? error : [error || 'Error occurred'];
      setAlert({
        type: 'ERROR',
        message: errorMsg,
      });
    }
  };

  const createWeight = async () => {
    setAlert(null);
    setIsLoading(true);
    const updatedTemplateData = { ...mainTemplateData };
    if (updatedTemplateData?.subGroup?.categories) {
      updatedTemplateData.subGroup.categories = updatedTemplateData.subGroup.categories.map(
        (category) => {
          const cap = capWeights[category.text];
          return {
            ...category,
            minCap: cap ? cap.minCap : null,
            maxCap: cap ? cap.maxCap : null,
          };
        }
      );
    }

    const payload = {
      ...updatedTemplateData,
      id: !isExisting ? weightName : undefined,
      description: weightDescription,
      settings: {
        ...updatedTemplateData?.settings,
        rounding: roundingValues,
        maxIterations: maxIterations ?? updatedTemplateData?.settings?.maxIterations,
      },
    };
    try {
      await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced`,
        token,
        payload
      );
      setIsLoading(false);
      dispatch({
        type: 'SHOW_SUCCESS_NOTIFICATION',
        payload: { msg: 'Advanced Weight created successfully' },
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      const errorMsg = Array.isArray(error) ? error : [error || 'Error creating advanced weight'];
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg: errorMsg },
      });
    }
  };

  const renderContent = (): JSX.Element | null => {
    switch (currentStep) {
      case 0:
        return (
          <ExistingAdvancedWeights
            token={token}
            datasetId={datasetId}
            setCurrentStep={setCurrentStep}
            setMainTemplateData={setMainTemplateData}
            setWeightName={setWeightName}
            setWeightDescription={setWeightDescription}
            setRoundingValues={setRoundingValues}
            setCapWeights={setCapWeights}
            setMaxIterations={setMaxIterations}
            setAlert={setAlert}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      case 1:
        return (
          <StepOne
            createUniverse={createUniverse}
            universeDefinition={universeDefinition}
            universeDescription={universeDescription}
            setUniverseDefinition={setUniverseDefinition}
            setUniverseDescription={setUniverseDescription}
            setCreateUniverse={setCreateUniverse}
            weightName={weightName}
            setWeightName={setWeightName}
            weightDescription={weightDescription}
            setWeightDescription={setWeightDescription}
          />
        );
      case 2:
        return (
          <StepTwo
            token={token}
            projectId={projectId}
            datasetId={datasetId}
            mainTemplateData={mainTemplateData}
            setMainTemplateData={setMainTemplateData}
            validateTemplateData={validateTemplateData}
            isValidating={isLoading}
            setShouldValidate={setShouldValidate}
          />
        );
      case 3:
        return (
          <StepThree
            templateData={mainTemplateData}
            capWeights={capWeights}
            setCapWeights={setCapWeights}
            maxIterations={maxIterations || mainTemplateData?.settings?.maxIterations}
            setMaxIterations={setMaxIterations}
          />
        );
      case 4:
        return <StepFour setRoundingValues={setRoundingValues} roundingValues={roundingValues} />;
      case 5:
        return (
          <StepFive
            templateData={mainTemplateData}
            roundingValues={roundingValues}
            capping={capWeights}
            weightName={weightName}
            weightDescription={weightDescription}
            isLoading={isLoading}
            maxIterations={maxIterations || mainTemplateData?.settings?.maxIterations}
          />
        );
      case 6:
        return <StepSix weightResult={weightResult} isLoading={isLoading} />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid d-flex flex-column p-2 h-100 position-relative">
      {currentStep !== 1 && !!universeDefinition && (
        <h3 className="p-2 font-weight-bold font-italic font-size-base">
          {universeDefinition ? `Universe: ${universeDescription}` : ''}
        </h3>
      )}
      <div className="min-h-30 px-4">
        {alert && <CustomAlert alert={alert} onClose={handleAlertClose} />}
      </div>

      {showValidationWarning && (
        <Dialog onClose={handleValidationWarningCancel} width={400}>
          <div className='p-2 w-75'>
            <p>You have unvalidated changes. If you continue, your changes will be lost.</p>
            <p>Do you wish to continue?</p>
          </div>

          <div className="k-dialog-buttongroup d-flex justify-content-end p-2">
            <Button className='mr-2' onClick={handleValidationWarningCancel}>Cancel</Button>
            <Button className='btn-primary' onClick={handleValidationWarningConfirm}>
              Continue
            </Button>
          </div>
        </Dialog>
      )}

      <div className="flex-grow-1 p-4 overflow-auto mb-16">{renderContent()}</div>

      {currentStep !== 0 && (
        <div className="position-absolute bottom-right-end bg-white z-index-9999 w-100">
          <ActionsButtons
            currentStep={currentStep}
            totalSteps={TOTAL_STEPS}
            isNextDisabled={isNextDisabled}
            isPreviousDisabled={currentStep === 1 || isLoading}
            onNext={handleNext}
            onBack={handleBack}
          />
        </div>
      )}
    </div>
  );
};

export default AdvancedWeightingWizard;
